'use strict';
import _ from 'lodash';
import Backbone from 'backbone';
import template from './../templates/vote-detail.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';
import CSSView from './css';
import cssTemplate from './../templates/vote-detail-css.ejs';
import { UI, VOTE_CONSTANTS } from './../constants';
import 'ion-rangeslider';
let styleId;

const BUTTON_LOADING = UI.BUTTON_LOADING;
const POLL_FREQUENCY = 2000;

export default BaseView.extend({
    className: 'view-vote-detail',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .prev-contestant': 'prevContestant',
            'click .next-contestant': 'nextContestant',
            'click .close': 'close',
            'click .btn-vote': 'vote'
        });
    },
    /**
     *
     */
    initialize: function(options) {
        _.bindAll(this, 'render');
        options = options || {};

        this.closeModal = options.closeModal;
        this.turboModel = options.turboModel;

        styleId = this.className + _.uniqueId();
        this.$el.addClass(styleId);
        this.listenTo(this.model, 'change:copy', this.render);

        this.voteOption = this.model.get('voteOption');
        this.category = this.model.get('currentCategory');
        this._voteAllocated = this.voteOption.get('voteCount') || 0;
        this.pollTimer = null;

        this._currentMin = this.model.get('voteOption').get('voteCount') || VOTE_CONSTANTS.MIN;

        this.listenTo(this.turboModel, 'change:voteLimit', () => {
            this.handleVoteCategoryChange();
        });

        this.listenTo(PubSub, 'category-votes-updated', () => {
          this.updateSlider();
          this.handleVoteCategoryChange();
        });
    },
    handleVoteCategoryChange: function(){
        this._voteAllocated = this.model.get('voteOption').get('voteCount') || 0;
        this._currentMin = this.model.get('voteOption').get('voteCount') || VOTE_CONSTANTS.MIN;


        this.render();
        this.renderedToDom();
    },

    renderSlider() {
      var that = this;
      var rangeMax = this.category.get('remainingVotes') + this.voteOption.get('voteCount');

      this.$('#vote-slider').ionRangeSlider({
        skin: 'round',
        type: 'single',
        grid: true,
        grid_num: 5,
        min: 0,
        max: this.turboModel.get('voteLimit'),
        from: 0,
        from_min: that.voteOption.get('voteCount') || 0,
        from_max: rangeMax,
        onChange: function(data) {
          that._voteAllocated = data.from;
          that.handleButtonState();
        }
      });
      this.slider = this.$('#vote-slider').data('ionRangeSlider');
    },
    /**
     *
     */
    updateSlider: function() {
      var voteCount = this.voteOption.get('voteCount');
      var remainingVotes = this.category.get('remainingVotes');

      if (!remainingVotes || !this.slider || !voteCount) {
        return;
      }

      var rangeMax = remainingVotes + voteCount;

      this.slider.update({
        from_min: voteCount,
        from_max: rangeMax,
        max: this.turboModel.get('voteLimit')
      });
    },

    handleButtonState: function() {
      var disableButton = (this._voteAllocated === this.voteOption.get('voteCount'));

      if (!this.voteOption.get('voteCount') && !this._voteAllocated) {
        disableButton = true;
      }

      if (disableButton) {
          this.$('.btn-vote').addClass('disabled');
          this.$('.btn-vote').addClass('btn-vote-disabled');
      } else {
          this.$('.btn-vote').removeClass('disabled');
          this.$('.btn-vote').removeClass('btn-vote-disabled');
      }
    },

    setRemainingVotes: function () {
      var isOpen = this.catOpen;
      var user = this.user.get('isAuthorized') || this.user.get('user');
      var remainingVotes = this.model.get('categoryCollection').get('remainingVotes');
      var voteCopy = this.model.get('copy').votes_left_copy;
      var voteCopySingular = this.model.get('copy').vote_left_copy;
      var singularNumbers = this.model.get('copy').singular_numbers.replace(/\s/g, '').split(',');
      var isSingular = singularNumbers.indexOf(`${remainingVotes}`) > -1;

      if (user && isOpen && (remainingVotes !== null && remainingVotes !== undefined)) {
        var countCopy = isSingular? voteCopySingular : voteCopy;
        this.$el.find('.description_count').html('<span>' + remainingVotes + ' </span>' + countCopy);
      } else {
        this.$el.find('.description_count').html(this.model.get('copy').description);
      }
    },
    /**
     * [renderedToDOM description]
     * @return {[type]} [description]
     */
    renderedToDom: function() {
      this.renderSlider();
      // this.setRemainingVotes();
        const voteOption = this.model.get('voteOption').toJSON();

        this.addClassTheme(voteOption.category_id);
        this.handleButtonState();
    },
    /**
     *
     */
    render: function() {
        if (this.CSSView) {
            this.CSSView.remove();
        }
        const cats = this.model.get('categories').getActiveCatClassNames();
        this.CSSView = new CSSView({
            model: _.extend(
                this.model.get('globalStyles'),
                this.model.get('copy')['customizations']
            ),
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: cssTemplate,
            categoryNames: cats
        });

        Backbone.$('head').append(this.CSSView.render().$el);
        const voteOption = this.model.get('voteOption');
        console.log(
            'vote-detail render',
            voteOption.collection.indexOf(voteOption),
            voteOption.collection.length
        );
        this.$el.html(
            template(
                _.extend(voteOption.toJSON(), {
                    copy: this.model.get('copy'),
                    model: voteOption.collection.indexOf(voteOption),
                    length: voteOption.collection.length,
                    video_uid: this.video_uid,
                    category_id: voteOption.get('category_id')
                })
            )
        );
        return this;
    },
    /**
     *
     */
    addClassTheme: function(cat) {
        let domClass = '';
        const el = document.getElementsByClassName(styleId)[0];
        const classList = el.classList;
        const customClasses =
            this.model.get('categories').getActiveCatClassNames() || [];

        if (_.isUndefined(cat) || cat == 'default') {
            domClass = 'default';
        } else {
            domClass = this.model
                .get('categories')
                .get(cat)
                .get('className');
        }
        //remove any classes from customClasses
        _.each(classList, function(c) {
            if (_.includes(customClasses, c)) {
                classList.remove(c);
            }
        });
        classList.add(domClass);
    },

    /**
     * [prevContestant description]
     * @return {[type]} [description]
     */
    prevContestant: function(e) {
        e.preventDefault();

        const voteOption = this.model.get('voteOption');

        if (this.$('.prev-contestant').hasClass('disabled')) {
            return;
        }
        console.log('prevContestant');
        const model = voteOption.collection.prev(voteOption);
        PubSub.trigger(
            'navigate',
            'vote-detail/' + model.get('category_id') + '-' + model.get('id')
        );
    },
    /**
     * [nextContestant description]
     * @return {[type]} [description]
     */
    nextContestant: function(e) {
        e.preventDefault();

        const voteOption = this.model.get('voteOption');

        if (this.$('.next-contestant').hasClass('disabled')) {
            return;
        }
        console.log('nextContestant');
        const model = voteOption.collection.next(voteOption);
        PubSub.trigger(
            'navigate',
            'vote-detail/' + model.get('category_id') + '-' + model.get('id')
        );
    },
    /**
     * [close description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    close: function() {
        this.closeModal();
    },
    /**
     * [vote description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    vote: function(e) {
        e.preventDefault();
        if(this._voteAllocated > 0){
            e.currentTarget.innerText = BUTTON_LOADING;
            e.currentTarget.disabled = true;
            const voteOption = this.model.get('voteOption');
            const total = this._voteAllocated;

            voteOption.set('votes', total);

            console.log('voteModel', voteOption);
            PubSub.trigger('vote', voteOption);

            //ICV-148: poll only if the is no user authenticated
            if (this.model.get('iheartModel').get('user') == null) {
                this.pollData(e);
            }
        }
    },
    /**
     * this function resets the state of the button to take care of an event where the user opted to close
     * the login window
     *
     */
    resetVoteButton: function(e) {
        const copy = this.model.get('copy').buttons.submit.default;
        e.currentTarget.innerText = copy;
        e.currentTarget.disabled = false;
    },
    pollData: function(e) {
        clearTimeout(this.pollTimer);

        this.pollTimer = setTimeout(
            this.resetVoteButton.bind(this, e),
            POLL_FREQUENCY
        );
    },

    remove: function() {
        clearTimeout(this.pollTimer);

        this.slider.destroy();

        return Backbone.View.prototype.remove.call(this);
    }
});
