'use strict';
import _ from 'lodash';

import template from './../templates/landing-category.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';

export default BaseView.extend({
    className: 'view-landing-category',
    tagName: 'li',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .category-marquee': 'selectCategory'
        });
    },
    /**
     *
     */
    initialize: function() {
        _.bindAll(this, 'render');
    },
    /**
     *
     */
    render: function() {
        console.log('render landing-category', this.model);
        this.$el.html(template(this.model));
        return this;
    },
    /**
     * [selectCategory description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    selectCategory: function() {
        console.log('selectCategory', this.model);
        PubSub.trigger('navigate', 'vote/' + this.model.id);
        PubSub.trigger('customize-theme', this.model.id);
    }
});
