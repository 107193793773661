module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<div class=\"wrapper-text\"> <p class=\"links\"> <% if (links.copyright.link.length>0 && displayCopyright) { %> <a href=\"<%- links.copyright.link %>\" target=\"<%- links.copyright.target %>\"> <small class=\"copyright\"><%- text.copyright %></small> </a> <% } %> <% if (links.terms.link.length>0 && displayTerms) { %> <a href=\"<%- links.terms.link %>\" target=\"<%- links.terms.target %>\"> <small class=\"terms\"><%- text.terms %></small> </a> <% } %> <% if (links.privacy_policy.link.length>0 && displayPrivacyPolicy) { %> <a href=\"<%- links.privacy_policy.link %>\" target=\"<%- links.privacy_policy.target %>\"> <small class=\"privacy_policy\"><%- text.privacy_policy %></small> </a> <% } %> <% if (links.rules.link.length>0 && displayRules) { %> <a href=\"<%- links.rules.link %>\" target=\"<%- links.rules.target %>\"> <small class=\"rules\"><%- text.rules %></small> </a> <% } %> </p> <p class=\"credit\"> <%- links.credit.link.length>0? '<a href=\"'+links.credit.link+'\" target=\"'+links.credit.target+'\">' : '' %> <%- text.credit %> <%- links.credit.link.length>0? '</a>' : '' %> </p> </div> "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"wrapper-text\"> <p class=\"links\"> ")
    ;  if (links.copyright.link.length>0 && displayCopyright) { 
    ; __append(" <a href=\"")
    ; __append( links.copyright.link )
    ; __append("\" target=\"")
    ; __append( links.copyright.target )
    ; __append("\"> <small class=\"copyright\">")
    ; __append( text.copyright )
    ; __append("</small> </a> ")
    ;  } 
    ; __append(" ")
    ;  if (links.terms.link.length>0 && displayTerms) { 
    ; __append(" <a href=\"")
    ; __append( links.terms.link )
    ; __append("\" target=\"")
    ; __append( links.terms.target )
    ; __append("\"> <small class=\"terms\">")
    ; __append( text.terms )
    ; __append("</small> </a> ")
    ;  } 
    ; __append(" ")
    ;  if (links.privacy_policy.link.length>0 && displayPrivacyPolicy) { 
    ; __append(" <a href=\"")
    ; __append( links.privacy_policy.link )
    ; __append("\" target=\"")
    ; __append( links.privacy_policy.target )
    ; __append("\"> <small class=\"privacy_policy\">")
    ; __append( text.privacy_policy )
    ; __append("</small> </a> ")
    ;  } 
    ; __append(" ")
    ;  if (links.rules.link.length>0 && displayRules) { 
    ; __append(" <a href=\"")
    ; __append( links.rules.link )
    ; __append("\" target=\"")
    ; __append( links.rules.target )
    ; __append("\"> <small class=\"rules\">")
    ; __append( text.rules )
    ; __append("</small> </a> ")
    ;  } 
    ; __append(" </p> <p class=\"credit\"> ")
    ; __append( links.credit.link.length>0? '<a href="'+links.credit.link+'" target="'+links.credit.target+'">' : '' )
    ; __append(" ")
    ; __append( text.credit )
    ; __append(" ")
    ; __append( links.credit.link.length>0? '</a>' : '' )
    ; __append(" </p> </div> ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}