'use strict';
import _ from 'lodash';
import template from './../templates/nav-category-item.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';

export default BaseView.extend({
    className: 'view-nav-category-item',
    tagName: 'li',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .category-link': 'toCategoryGrid',
            'touchend .category-link': 'toCategoryGrid'
        });
    },
    /**
     *
     */
    initialize: function() {
        _.bindAll(this, 'render');
    },
    /**
     *
     */
    render: function() {
        this.$el.html(template(this.model.toJSON()));
        return this;
    },
    /**
     * [toCategoryGrid description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    toCategoryGrid: function(e) {
        e.preventDefault();
        e.stopPropagation();
        console.log('toCategoryGrid', this.model.id);
        PubSub.trigger('navItemSelected', this.model.id);
        PubSub.trigger('customize-theme', this.model.id);
    }
});
