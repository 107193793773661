'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
import template from './../templates/nav.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';
import CSSView from './css';
import cssTemplate from './../templates/nav-css.ejs';

let styleId;

export default BaseView.extend({
    className: 'view-nav',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .swipe-nav .prev': 'toPrev',
            'click .swipe-nav .next': 'toNext',
            'click .carousel-indicators li': 'toIndex'
        });
    },
    /**
     *
     */
    initialize: function() {
        _.bindAll(this, 'render');

        styleId = this.className + _.uniqueId();
        this.$el.addClass(styleId);

        this.wrapperHeight = 0;
        Backbone.$(window).on(
            'resize',
            _.bind(function() {
                this.wrapperHeight = 0;
            }, this)
        );
    },
    /**
     *
     */
    render: function() {
        if (this.CSSView) {
            this.CSSView.remove();
        }

        this.CSSView = new CSSView({
            model: this.model.get('copy')['customizations'],
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: cssTemplate
        });

        Backbone.$('head').append(this.CSSView.render().$el);

        const currentCategory = this.model
            .get('categoryGroupCollection')
            .getCategoryById(this.model.get('activeCategoryId'));
        const prevCategoryName = this.model
            .get('categoryGroupCollection')
            .getPrevCategoryName(currentCategory.id);
        const nextCategoryName = this.model
            .get('categoryGroupCollection')
            .getNextCategoryName(currentCategory.id);
        const currentIndex = this.model
            .get('categoryGroupCollection')
            .getCategoryIndexById(this.model.get('activeCategoryId'));

        console.log(
            'render nav',
            currentCategory,
            prevCategoryName,
            nextCategoryName,
            currentIndex
        );

        this.$el.html(
            template(
                _.extend(
                    {
                        prevCategoryName: prevCategoryName,
                        nextCategoryName: nextCategoryName,
                        group_count: this.model
                            .get('categoryGroupCollection')
                            .getCategoryCount(),
                        current_index: currentIndex
                    },
                    currentCategory.toJSON()
                )
            )
        );

        this.$('.swipe-wrapper').swipe({
            swipeLeft: _.bind(function() {
                console.log('swipeLeft to next');
                this.toNext();
            }, this),
            swipeRight: _.bind(function() {
                console.log('swipeRight to previous');
                this.toPrev();
            }, this)
        });

        return this;
    },
    /**
     * [toPrev description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    toPrev: function(e) {
        if (e) {
            e.preventDefault();
        }
        const currentCategoryId = this.model.get('activeCategoryId');
        const prevCategory = this.model
            .get('categoryGroupCollection')
            .getPrevCategoryById(currentCategoryId);
        PubSub.trigger('navigate', 'vote/' + prevCategory.id);
    },
    /**
     * [toNext description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    toNext: function(e) {
        if (e) {
            e.preventDefault();
        }
        const currentCategoryId = this.model.get('activeCategoryId');
        const nextCategory = this.model
            .get('categoryGroupCollection')
            .getNextCategoryById(currentCategoryId);
        PubSub.trigger('navigate', 'vote/' + nextCategory.id);
    },
    toIndex: function(e) {
        if (e) {
            e.preventDefault();
        }
        const selectedIndex = parseInt(
            $(e.currentTarget).attr('data-order'),
            10
        );
        const toCategory = this.model
            .get('categoryGroupCollection')
            .getCategoryByIndex(selectedIndex);
        PubSub.trigger('navigate', 'vote/' + toCategory.id);
    }
});
