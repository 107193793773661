'use strict';
import _ from 'lodash';
import Backbone from 'backbone';
import Model from './../models/category-group';

export default Backbone.Collection.extend({
    model: Model,
    /**
     *
     */
    initialize: function() {
        _.bindAll(this, 'getCategoryById');
    },
    /**
     * [getCategoryById description]
     * @param  {[type]} categoryId [description]
     * @return {[type]}             [description]
     */
    getCategoryById: function(categoryId) {
        let found;
        this.forEach(function(item) {
            if (found) {
                return true;
            }
            // console.log('getCategoryById', found)
            found = item.get('categoryCollection').get(categoryId);
        });
        return found;
    },
    /**
     * [getCategoryById description]
     * @param  {[type]} categoryId [description]
     * @return {[type]}             [description]
     */
    getCategoryName: function(categoryId) {
        let found;
        this.forEach(function(item) {
            if (found) {
                return true;
            }
            // console.log('getCategoryById', found)
            found = item.get('categoryCollection').get(categoryId);
        });
        return found && typeof found.attributes === 'object'
            ? found.attributes.name
            : '';
    },
    /**
     * [getNextCategoryById description]
     * @param  {[type]} categoryId [description]
     * @return {[type]}             [description]
     */
    getNextCategoryById: function(categoryId) {
        let found;
        let allModels = [];
        let currentIndex;
        let nextIndex;

        this.forEach(function(item) {
            allModels.push(
                _.clone(item.get('categoryCollection').models, true)
            );
        });

        allModels = _.flatten(allModels, true);
        currentIndex = _.findIndex(allModels, function(model) {
            return model.id === categoryId;
        });
        nextIndex = currentIndex + 1 >= allModels.length ? 0 : currentIndex + 1;
        found = _.at(allModels, nextIndex)[0];

        console.log(
            'getNextCategoryById',
            categoryId,
            allModels,
            currentIndex,
            found
        );

        return found;
    },
    /**
     * [getPrevCategoryById description]
     * @param  {[type]} categoryId [description]
     * @return {[type]}             [description]
     */
    getPrevCategoryById: function(categoryId) {
        let found;
        let allModels = [];
        let currentIndex;
        let prevIndex;

        this.forEach(function(item) {
            allModels.push(
                _.clone(item.get('categoryCollection').models, true)
            );
        });

        allModels = _.flatten(allModels, true);
        currentIndex = _.findIndex(allModels, function(model) {
            return model.id === categoryId;
        });
        prevIndex =
            currentIndex - 1 < 0 ? allModels.length - 1 : currentIndex - 1;
        found = _.at(allModels, prevIndex)[0];

        console.log(
            'getNextCategoryById',
            categoryId,
            allModels,
            currentIndex,
            found
        );

        return found;
    },
    getPrevCategoryName: function(categoryId) {
        let found;
        let allModels = [];
        let currentIndex;
        let prevIndex;

        this.forEach(function(item) {
            allModels.push(
                _.clone(item.get('categoryCollection').models, true)
            );
        });

        allModels = _.flatten(allModels, true);
        currentIndex = _.findIndex(allModels, function(model) {
            return model.id === categoryId;
        });
        prevIndex =
            currentIndex - 1 < 0 ? allModels.length - 1 : currentIndex - 1;
        found = _.at(allModels, prevIndex)[0];

        console.log(
            'getPrevCategoryName',
            categoryId,
            allModels,
            currentIndex,
            found
        );

        return found && typeof found.attributes === 'object'
            ? found.attributes.name
            : '';
    },
    getNextCategoryName: function(categoryId) {
        let found;
        let allModels = [];
        let currentIndex;
        let nextIndex;

        this.forEach(function(item) {
            allModels.push(
                _.clone(item.get('categoryCollection').models, true)
            );
        });

        allModels = _.flatten(allModels, true);
        currentIndex = _.findIndex(allModels, function(model) {
            return model.id === categoryId;
        });
        nextIndex = currentIndex + 1 >= allModels.length ? 0 : currentIndex + 1;
        found = _.at(allModels, nextIndex)[0];

        console.log(
            'getNextCategoryName',
            categoryId,
            allModels,
            currentIndex,
            found
        );

        return found && typeof found.attributes === 'object'
            ? found.attributes.name
            : '';
    },
    getCategoryCount: function() {
        let allModels = [];

        this.forEach(function(item) {
            allModels.push(
                _.clone(item.get('categoryCollection').models, true)
            );
        });

        allModels = _.flatten(allModels, true);
        return allModels.length;
    },
    getCategoryIndexById: function(categoryId) {
        let allModels = [];
        let currentIndex;

        this.forEach(function(item) {
            allModels.push(
                _.clone(item.get('categoryCollection').models, true)
            );
        });

        allModels = _.flatten(allModels, true);
        currentIndex = _.findIndex(allModels, function(model) {
            return model.id === categoryId;
        });

        console.log('getCategoryIndexById', currentIndex);

        return currentIndex;
    },
    getCategoryByIndex: function(index) {
        let allModels = [];

        this.forEach(function(item) {
            allModels.push(
                _.clone(item.get('categoryCollection').models, true)
            );
        });

        allModels = _.flatten(allModels, true);
        return allModels[index];
    },
    /**
     * Fetches the model which was selected by the user
     * NOTE: THIS FUNCTION WILL ONLY WORK IF EVERY CONTESTANT HAS A UNIQUE
     * @param  {[type]} optionId : id of a voteOption
     * @return {[type]}           [description]
     */
    getOptionById: function(optionId) {
        let found;
        this.forEach(function(item) {
            if (found) {
                return true;
            }
            // console.log('getOptionById', found)
            item.get('categoryCollection').forEach(function(item) {
                if (found) {
                    return true;
                }
                found = item.get('voteCollection').get(optionId);
            });
        });
        return found;
    },
    /**
     * Fetches the model by categoryId and id
     * @param  {String} key : comes is a form of category-id (e.g. 2-A1)
     * @return {model} : the model that was found
     */
    getOptionByCatAndId: function(key) {
        let id;
        let cat;
        let found;
        key = key.split('-');
        cat = key[0];
        id = key[1];

        this.forEach(function(item) {
            if (found) {
                return true;
            }
            item.get('categoryCollection').forEach(function(item) {
                if (item.get('id') === cat && !found) {
                    found = item.get('voteCollection').get(id);

                    return true;
                }
            });
        });
        return found;
    }
});
