module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<% if (window != 0) { %> <% if (user != null) { %> <% if (user.trim().length > 0) { %> <span class=\"greeting\"><%- copy.greeting %> <span class=\"username\"><%- (user.length > 34) ? user.substr(0, 31) + '...' : user %>!</span> <% } %> </span> <span class=\"logout\" data-track=\"<%- copy.buttons.logout.track %>\"> &nbsp; <%- copy.buttons.logout.default %> </span> <% } else { %> <span target=\"_blank\" alt=\"log-in\" class=\"login\" data-track=\"<%- copy.buttons.login.track %>\"> <%- copy.buttons.login.default %> </span> <span class=\"divider\"> | </span> <span target=\"_blank\" alt=\"sign-up\" class=\"signup\" data-track=\"<%- copy.buttons.signup.track %>\"> <%- copy.buttons.signup.default %> </span> <% } %> <% } %> "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  if (window != 0) { 
    ; __append(" ")
    ;  if (user != null) { 
    ; __append(" ")
    ;  if (user.trim().length > 0) { 
    ; __append(" <span class=\"greeting\">")
    ; __append( copy.greeting )
    ; __append(" <span class=\"username\">")
    ; __append( (user.length > 34) ? user.substr(0, 31) + '...' : user )
    ; __append("!</span> ")
    ;  } 
    ; __append(" </span> <span class=\"logout\" data-track=\"")
    ; __append( copy.buttons.logout.track )
    ; __append("\"> &nbsp; ")
    ; __append( copy.buttons.logout.default )
    ; __append(" </span> ")
    ;  } else { 
    ; __append(" <span target=\"_blank\" alt=\"log-in\" class=\"login\" data-track=\"")
    ; __append( copy.buttons.login.track )
    ; __append("\"> ")
    ; __append( copy.buttons.login.default )
    ; __append(" </span> <span class=\"divider\"> | </span> <span target=\"_blank\" alt=\"sign-up\" class=\"signup\" data-track=\"")
    ; __append( copy.buttons.signup.track )
    ; __append("\"> ")
    ; __append( copy.buttons.signup.default )
    ; __append(" </span> ")
    ;  } 
    ; __append(" ")
    ;  } 
    ; __append(" ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}