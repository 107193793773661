'use strict';
import _ from 'lodash';
import Backbone from 'backbone';
import Model from './../models/category';

export default Backbone.Collection.extend({
    model: Model,
    /**
     *
     */
    initialize: function() {},
    /**
     * [next description]
     * @param  {[type]}   option [description]
     * @return {Function}        [description]
     */
    next: function(option) {
        if (!this.contains(option)) {
            return false;
        }
        const model = this.at(this.indexOf(option) + 1);

        if (_.isUndefined(model)) {
            model = this.last();
        }

        return model;
    },
    /**
     * [prev description]
     * @param  {[type]} option [description]
     * @return {[type]}        [description]
     */
    prev: function(option) {
        if (!this.contains(option)) {
            return false;
        }
        const model = this.at(this.indexOf(option) - 1);

        if (_.isUndefined(model)) {
            model = this.first();
        }

        return model;
    },

    getActiveCatClassNames: function() {
        const cats = [];
        _.each(this.models, function(cat) {
            cats.push(cat.get('className'));
        });

        return cats;
    }
});
