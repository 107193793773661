import { TRUTHY_STRINGS } from './../constants';
import { DEVICE_TYPES } from './../constants/vote';
import _ from 'lodash';
/**
 *
 * @param string
 * @returns {string}
 */
export const capitalize = (string) => {
    return (
        string.charAt(0).toUpperCase() + string.substring(1).toLowerCase()
    );
};
/**
 * Get query string param by name
 * window.location.search()
 *
 * @param name - String of param name
 * @return {string} value or empty string if not found
 */
export const getQueryParamByName = (name) => {
    const regexS = '[\\?&]' + name + '=([^&#]*)';
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.search);
    if (results === null) {
        return '';
    } else {
        return decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
};
/**
 * https://github.com/kvz/phpjs/blob/master/functions/url/urlencode.js
 * @param url
 * @return {string} encoded url
 */
export const urlencode = (str) => {
    str = (str + '').toString();
    // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
    // PHP behavior, you would need to add '.replace(/~/g, '%7E');' to the following.
    return encodeURIComponent(str)
        .replace(/!/g, '%21')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\*/g, '%2A')
        .replace(/%20/g, '+');
};

/**
 * @description: determines if a dom element has a css class
 * @param  {Node} el: a dom element
 * @param  {String}  className: a css class
 * @return {Boolean}: returns true if el has a class of className
 */
export const hasClass = (el, className) => {
    if (el.classList) {
        return el.classList.contains(className);
    } else {
        return !!el.className.match(
            new RegExp('(\\s|^)' + className + '(\\s|$)')
        );
    }
};
/**
 * @description: adds a css class to a dom element
 * @param {[Node]} el           : dom element
 * @param {[String]} className  : a css class
 */
export const addClass = (el, className) => {
    if (el.classList) {
        el.classList.add(className);
    } else if (!hasClass(el, className)) {
        el.className += ' ' + className;
    }
};
/**
 * removes a css class from a dom element
 * @param  {[Node]} el          : dom element
 * @param  {[String]} className : a css class
 */
export const removeClass = (el, className) => {
    if (el.classList) {
        el.classList.remove(className);
    } else if (hasClass(el, className)) {
        const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
        el.className = el.className.replace(reg, ' ');
    }
};

export const checkIfTrue = (value) => {
    if (typeof value === 'string') {
        return TRUTHY_STRINGS.indexOf(value.toLowerCase()) >= 0;
    }

    return Boolean(value);
};


export const paramsLiteralToQsp = (params) => {
    const paramsArr = [];
    let paramsStr = '';
    _.each(params, _.bind(function(value, key) {
        if (typeof value !== 'undefined') {
            paramsArr.push(key + '=' + fixedEncodeURIComponent(value));
        }
    }, this));
    paramsStr = paramsArr.join('&');
    return paramsStr;
};


export const fixedEncodeURIComponent = (str) => {
    return encodeURIComponent(str).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
}

export const getDeviceType = () => {
    const deviceType = getQueryParamByName('device_type');

    switch(deviceType){
        case DEVICE_TYPES.INSTAGRAM:
            return DEVICE_TYPES.INSTAGRAM
        case DEVICE_TYPES.NATIVE:
            return DEVICE_TYPES.NATIVE;
        default:
            return DEVICE_TYPES.WEB

    }
}