'use strict';
import _ from 'lodash';
import Backbone from 'backbone';

import template from './../templates/footer.ejs';
import BaseView from './../views/base';
import CSSView from './css';
import PubSub from '../PubSub';

import cssTemplate from './../templates/footer-css.ejs';

// util
import { checkIfTrue } from './../util/helpers';

let styleId;

const Footer = BaseView.extend({
    className: 'view-footer',
    /**
     *
     */
    initialize: function(options) {
        _.bindAll(this, 'render');
        options = options || {};
        this.categories = options.categories;

        styleId = this.className + _.uniqueId();
        this.$el.addClass(styleId);
        this.listenTo(this.model, 'change:copy', this.render);

        this.listenTo(
            PubSub,
            'customize-theme',
            _.bind(this.renderedToDom, this)
        );
    },
    /**
     *
     */
    render: function() {
        if (this.CSSView) {
            this.CSSView.remove();
        }
        const cats = this.categories.getActiveCatClassNames();
        this.CSSView = new CSSView({
            model: this.model.get('copy')['customizations'],
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: cssTemplate,
            categoryNames: cats
        });
        const copy = this.model.get('copy');

        Backbone.$('head').append(this.CSSView.render().$el);
        this.$el.html(template(_.extend({}, copy, {
            displayTerms: checkIfTrue(copy.customizations.display_copyright),
            displayCopyright: checkIfTrue(copy.customizations.display_copyright),
            displayPrivacyPolicy: checkIfTrue(copy.customizations.display_privacy_policy),
            displayRules: checkIfTrue(copy.customizations.display_rules)
        })));
        return this;
    },
    /**
     *
     */
    renderedToDom: function(cat) {
        let domClass = '';
        const el = document.getElementsByClassName(styleId)[0];
        const classList = el.classList;
        const customClasses = this.categories.getActiveCatClassNames() || [];

        if (_.isUndefined(cat) || cat == 'default') {
            domClass = 'default';
        } else {
            domClass = this.categories.get(cat).get('className');
        }

        //remove any classes from customClasses
        _.each(classList, function(c) {
            if(_.includes(customClasses, c)){
                classList.remove(c);
            }
        });
        classList.add(domClass);
    }
});

export default Footer;
