'use strict';
import _ from 'lodash';
import template from './../templates/user.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';

export default BaseView.extend({
    template: template,
    className: 'view-user',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .logout': 'logout',
            'click .login': 'login',
            'click .signup': 'signup',
        });
    },
    /**
     *
     */
    initialize: function() {
        _.bindAll(this, 'render');

        this.listenTo(this.model, 'change:user', _.bind(this.render, this));
        this.listenTo(this.model, 'change:window', _.bind(this.render, this));
    },
    /**
     *
     */
    render: function() {
        this.$el.html(this.template(this.model.toJSON()));
        return this;
    },
    /**
     * [logout description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    logout: function() {
        PubSub.trigger('userLogout');
    },
    /**
     * [login description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    login: function() {
        PubSub.trigger('user-login');
    },
    /**
     * [signup description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    signup: function() {
        PubSub.trigger('user-signup');
    }
});
