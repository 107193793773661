import Backbone from 'backbone';
import _ from 'lodash';
import PubSub from '../PubSub';

export default Backbone.Model.extend({
    defaults: {
        user: null,
        profileId: null,
        sessionId: null,
        scriptLoaded: false,
        authResponse: null,
        userAuthStatus: null
    },
    /**
     * @description Add the Facebook SDK for JavaScript
     * consult docs (https://developers.facebook.com/docs/) for reference
     *
     */
    initialize: function(attrs, options) {
        this.controller = options.controller;
        console.log('initialize facebook model', attrs, options, this);

        _.bindAll(
            this,
            'loadedScript',
            'checkUserLoginStatus',
            'signup',
            'login',
            'logout',
            'setUserData'
        );

        /**
         * Listeners for change of states of this model
         */
        //this.on("change:authResponse", this.setUserDataInSync, this);

        console.info('IHR initialize');

        if (typeof IHR === 'object') {
            this.set('scriptLoaded', true);
            this.checkUserLoginStatus();

            return;
        }

        this.loadScriptAsync(
            document,
            'script',
            'iheartradio-jssdk',
            this.loadedScript
        );
    },
    /**
     *
     */
    loadScriptAsync: function(d, s, id, cb) {
        console.info('IHR loadScriptAsync', id);
        let js;
        const fjs = d.getElementsByTagName(s)[0];

        if (d.getElementById(id)) {
            return;
        }

        js = d.createElement(s);
        js.id = id;
        js.src = 'https://www.iheart.com/sdk/sdk.js';

        js.onload = js.onreadystatechange = function() {
            const rs = this.readyState;
            if (rs && rs != 'complete' && rs != 'loaded') {
                return;
            }
            if (typeof cb === 'function') {
                cb();
            }
        };

        fjs.parentNode.insertBefore(js, fjs);
    },
    /**
     * @description SDKs Async Callback, triggered once the script has finished loading
     * Any code that you want to run after the SDK is loaded should be placed within this function and after the call to FB.init.
     * Any kind of JavaScript can be used here, but any SDK functions must be called after FB.init.
     *
     */
    loadedScript: function() {
        /**
         * After loading the JavaScript SDK, call FB.init to initialize the SDK with your app ID.
         * This will let you make calls against the Facebook API.
         */
        console.info('IHR loadedScript - initialized', IHR.initialized);

        if (IHR.initialized) return;

        IHR.initialize({
            container: 'ihr-root',
            host: 'https://www.iheart.com'
        }).then(() => {
          this.set('scriptLoaded', true);

          /**
           * Check User Login Status when they open this app the first time
           */
          this.checkUserLoginStatus();
        });
    },
    /**
     *
     */
    checkUserLoginStatus: function() {
      console.log('IHR checkUserLoginStatus');

      IHR.checkAuthStatus(function(authenticated) {
          console.log('IHR authenticated', authenticated);
          if (authenticated) {
              PubSub.trigger('iheart-auth-success', authenticated);
          }
      });
    },
    /**
     *
     */
    setUserData: function(data) {
        console.log('IHR setUserData');
        this.set({ user: data });
    },
    /**
     *
     */
    login: function() {
      console.log('IHR login');

      IHR.login({
        signup: false,
        success: (data) => {
          console.log('IHR login success', data);
          PubSub.trigger('iheart-auth-success', data);
        },
        error: (err) => {
          console.log('IHR login error', err);
        }
      });
    },
    /**
     * [logout description]
     * @return {[type]} [description]
     */
    logout: function() {
      console.log('IHR logout');

      IHR.api.profile.logout({
        success: (data) => {
          console.log('IHR logout success', data);
          this.set(this.defaults);
        },
        error: (err) => {
          console.log('IHR logout error', err);
        }
      });
    },
    /**
     *
     */
    signup: function() {
      console.log('IHR signup');

      IHR.signup({
        success: (data) => {
          console.log('IHR signup success', data);
          PubSub.trigger('iheart-auth-success', data);
        },
        error: (err) => {
          console.log('IHR signup error', err);
        }
      });
    },
    /**
     *
     */
    getUserProfile: function() {
      console.log('IHR retrieving iheart profile...');
      IHR.api.profile.get({
        success: (data) => {
          console.log('IHR getUserProfile success', data, data.accountType);
          if (data.accountType === 'ANONYMOUS') {
              PubSub.trigger('user-anonymous');
              return;
          }
          PubSub.trigger('user-data-fetched', data);
        },
        error: (err) => {
          console.log('IHR getUserProfile error', err);
          PubSub.trigger('navigate', 'error/iheart');
        }
      });
    }
});
