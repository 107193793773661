'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
import template from './../templates/nav-category.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';
import ViewNavCategoryGroup from './nav-category-group';
import ViewNavCategoryItem from './nav-category-item';
import CSSView from './css';
import cssTemplate from './../templates/nav-category-css.ejs';

let styleId;

let carouselActiveIndex;
let carouselMobileIndex;
let carouselDesktopIndex;
let selectedCategoryGroup;

export default BaseView.extend({
    className: 'view-nav-category',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .menu-toggler': 'toggleMenu',
            'click .group-carousel-control.prev': 'carouselPrev',
            'click .group-carousel-control.next': 'carouselNext',
            'click .carousel-indicators li': 'carouselToIndex'
        });
    },
    /**
     *
     */
    initialize: function(options) {
        _.bindAll(this, 'render');

        options = options || {};

        styleId = this.className + _.uniqueId();
        this.$el.addClass(styleId);
        this.listenTo(this.model, 'change:copy', this.render);

        selectedCategoryGroup = this.model
            .get('categoryGroupCollection')
            .get(this.model.get('activeCategoryGroupId'));
        const selectedGroupIndex = this.model
            .get('categoryGroupCollection')
            .indexOf(selectedCategoryGroup);

        carouselMobileIndex = selectedGroupIndex;
        carouselDesktopIndex = Math.floor(selectedGroupIndex / 3);
        carouselActiveIndex = this.model.get('mobileBreakpoint')
            ? carouselMobileIndex
            : carouselDesktopIndex;

        this.listenTo(
            PubSub,
            'navActiveGroup',
            this.changeActiveGroup.bind(this)
        );
        this.listenTo(
            PubSub,
            'navItemSelected',
            this.changeCategory.bind(this)
        );
        this.listenTo(PubSub, 'change-theme', this.renderedToDom.bind(this));

        this.callbacks = options.callbacks || {};
    },
    /**
     *
     */
    render: function() {
        if (this.CSSView) {
            this.CSSView.remove();
        }
        const cats = this.model.get('categories').getActiveCatClassNames();

        this.CSSView = new CSSView({
            model: this.model.get('copy')['customizations'],
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: cssTemplate,
            categoryNames: cats
        });

        Backbone.$('head').append(this.CSSView.render().$el);

        const groupNav = this.model.get('categoryGroupCollection').length > 1;
        this.$el.html(
            template(
                _.extend(_.clone(this.model.get('copy'), true), {
                    group_nav: groupNav,
                    group_count: this.model.get('categoryGroupCollection')
                        .length,
                    toggleState: this.callbacks.storageFetch(
                        'nav-category-menu'
                    )
                })
            )
        );

        this.renderCategoryGroup();
        this.renderCategoryItems();

        $(window).resize(_.bind(this.calculateLayout, this));

        this.$('.group-carousel').swipe({
            swipeLeft: _.bind(function() {
                console.log('swipeLeft to next');
                this.carouselNext();
            }, this),
            swipeRight: _.bind(function() {
                console.log('swipeRight to previous');
                this.carouselPrev();
            }, this)
        });

        return this;
    },
    /**
     *
     */
    renderedToDom: function(cat) {
        let domClass = '';
        const el = document.getElementsByClassName(styleId)[0];
        const classList = el.classList;
        const customClasses =
            this.model.get('categories').getActiveCatClassNames() || [];

        if (_.isUndefined(cat) || cat == 'default') {
            domClass = 'default';
        } else {
            domClass = this.model
                .get('categories')
                .get(cat)
                .get('className');
        }
        //remove any classes from customClasses
        _.each(classList, function(c) {
            if (_.includes(customClasses, c)) {
                classList.remove(c);
            }
        });
        classList.add(domClass);
    },
    /**
     * [renderCategoryGroup description]
     * @return {[type]} [description]
     */
    renderCategoryGroup: function() {
        const groupNav = this.model.get('categoryGroupCollection').length > 1;
        if (!groupNav) {
            return;
        }

        const partialViews = [];

        this.model.get('categoryGroupCollection').forEach(
            _.bind(function(item, index) {
                partialViews.push(
                    new ViewNavCategoryGroup({
                        model: item,
                        className:
                            'view-nav-category-group' +
                            (item.id === selectedCategoryGroup.id
                                ? ' active'
                                : '')
                    })
                        .render()
                        .$el.attr('data-order', index + '')
                );
            }, this)
        );

        this.$('.group-carousel-inner').html(partialViews);

        setTimeout(_.bind(this.updateCarouselPosition, this), 300);
    },
    /**
     * [renderCategoryItems description]
     * @return {[type]} [description]
     */
    renderCategoryItems: function() {
        const groupNav = this.model.get('categoryGroupCollection').length > 1;
        const partialViews = [];
        if (groupNav) {
            selectedCategoryGroup.get('categoryCollection').forEach(
                _.bind(function(item) {
                    partialViews.push(
                        new ViewNavCategoryItem({
                            model: item,
                            className:
                                'view-nav-category-item' +
                                (item.id === this.model.get('activeCategoryId')
                                    ? ' active'
                                    : '')
                        }).render().$el
                    );
                }, this)
            );
        } else {
            this.model.get('categoryGroupCollection').forEach(
                _.bind(function(item) {
                    item.get('categoryCollection').forEach(
                        _.bind(function(item) {
                            partialViews.push(
                                new ViewNavCategoryItem({
                                    model: item,
                                    className:
                                        'view-nav-category-item' +
                                        (item.id ===
                                        this.model.get('activeCategoryId')
                                            ? ' active'
                                            : '')
                                }).render().$el
                            );
                        }, this)
                    );
                }, this)
            );
        }
        this.$('.category-collection-wrapper ul').html(partialViews);
    },
    /**
     * [toggleMenu description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    toggleMenu: function(e, forceClose) {
        if (e) {
            e.preventDefault();
        }

        const isForceCloseDefined = forceClose !== undefined;
        const $menuToggler = this.$('.menu-toggler');
        const $menuContent = this.$('.menu-content');
        const isOpen = isForceCloseDefined
            ? forceClose
            : $menuToggler.hasClass('menu-state-open');

        $menuToggler.toggleClass('menu-state-open', !isOpen);

        if (isOpen) {
            $menuContent.slideUp('fast');
        } else {
            $menuContent.slideDown('fast');
            this.updateCarouselOnResize();
        }
    },
    changeCategory: function(id) {
        if (!this.isActiveView) {
            return;
        }
        this.$('.menu-toggler').alterClass('menu-state-*', 'menu-state-closed');
        PubSub.trigger('storage-save', 'nav-category-menu', 'closed');

        this.$('.menu-content').slideUp('fast', function() {
            PubSub.trigger('navigate', 'vote/' + id);
        });
    },
    /**
     * [changeActiveGroup description]
     * @param  {[type]} groupId [description]
     * @return {[type]}          [description]
     */
    changeActiveGroup: function(groupId) {
        if (!this.isActiveView) {
            return;
        }
        selectedCategoryGroup = this.model
            .get('categoryGroupCollection')
            .get(groupId);
        const activeGroupIndex = this.model
            .get('categoryGroupCollection')
            .indexOf(selectedCategoryGroup);
        const isMobile = this.$('.group-carousel-inner').width() < 660;
        carouselMobileIndex = activeGroupIndex;
        carouselDesktopIndex = Math.floor(activeGroupIndex / 3);
        carouselActiveIndex = isMobile
            ? carouselMobileIndex
            : carouselDesktopIndex;

        this.renderCategoryItems();
    },

    /**
     * [carouselPrev description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    carouselPrev: function(e) {
        if (e) {
            e.preventDefault();
        }

        if (this.$('.group-carousel-control.prev').hasClass('disabled')) {
            return;
        }

        const isMobile = this.$('.group-carousel-inner').width() < 660;
        if (isMobile) {
            carouselMobileIndex = carouselActiveIndex = carouselMobileIndex - 1;
            PubSub.trigger(
                'navActiveGroup',
                this.model
                    .get('categoryGroupCollection')
                    .at(carouselMobileIndex).id
            );
        } else {
            carouselDesktopIndex = carouselActiveIndex =
                carouselDesktopIndex - 1;
        }

        this.updateCarouselPosition();
    },

    /**
     * [carouselNext description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    carouselNext: function(e) {
        if (e) {
            e.preventDefault();
        }

        if (this.$('.group-carousel-control.next').hasClass('disabled')) {
            return;
        }

        const isMobile = this.$('.group-carousel-inner').width() < 660;
        if (isMobile) {
            carouselMobileIndex = carouselActiveIndex = carouselMobileIndex + 1;
            PubSub.trigger(
                'navActiveGroup',
                this.model
                    .get('categoryGroupCollection')
                    .at(carouselMobileIndex).id
            );
        } else {
            carouselDesktopIndex = carouselActiveIndex =
                carouselDesktopIndex + 1;
        }

        this.updateCarouselPosition();
    },
    /**
     * [updateCarouselPosition description]
     * @return {[type]} [description]
     */
    updateCarouselPosition: function() {
        this.toggleCarouselControls();
        this.updateActiveIndicator();

        const groupCount = this.model.get('categoryGroupCollection').length;
        const isMobile = this.$('.group-carousel-inner').width() < 660;
        const visibleCount = !isMobile ? 3 : 1;
        const move = this.$('.view-nav-category-group').width();

        console.log(
            'updateCarouselPosition',
            'groupCount: ' + groupCount,
            'isMobile: ' + isMobile,
            'visibleCount: ' + visibleCount,
            'move: ' + move
        );

        this.$('.view-nav-category-group').each(function() {
            const order = parseInt($(this).attr('data-order'), 10);
            const defaultPosition = move * order;
            const newPosition = defaultPosition - move * carouselActiveIndex;

            console.log(
                'updateCarouselPosition animate',
                'order: ' + order,
                'defaultPosition: ' + defaultPosition,
                'newPosition: ' + newPosition,
                'carouselActiveIndex: ' + carouselActiveIndex
            );
            $(this).animate(
                {
                    left: newPosition
                },
                500,
                function() {
                    console.log('animation complete');
                }
            );
        });
    },
    /**
     * [toggleCarouselControls description]
     * @return {[type]} [description]
     */
    toggleCarouselControls: function() {
        const groupCount = this.model.get('categoryGroupCollection').length;
        const isMobile = this.$('.group-carousel-inner').width() < 660;
        const visibleCount = !isMobile ? 3 : 1;
        const limitActiveIndex = Math.floor(groupCount / visibleCount);
        this.$('.group-carousel-control.prev').toggleClass(
            'disabled',
            carouselActiveIndex === 0
        );
        this.$('.group-carousel-control.next').toggleClass(
            'disabled',
            groupCount < visibleCount ||
                carouselActiveIndex === limitActiveIndex ||
                carouselActiveIndex === groupCount - 1
        );
    },
    /**
     * [carouselToIndex description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    carouselToIndex: function(e) {
        e.preventDefault();
        const selectedIndex = parseInt(
            $(e.currentTarget).attr('data-order'),
            10
        );
        console.log('carouselToIndex', selectedIndex);
        carouselMobileIndex = carouselActiveIndex = selectedIndex;
        carouselDesktopIndex = Math.floor(selectedIndex / 3);
        this.updateCarouselPosition();
        PubSub.trigger(
            'navActiveGroup',
            this.model.get('categoryGroupCollection').at(selectedIndex).id
        );
    },
    /**
     * [updateActiveIndicator description]
     * @return {[type]} [description]
     */
    updateActiveIndicator: function() {
        this.$('.carousel-indicators li').each(function() {
            $(this).toggleClass(
                'active',
                carouselActiveIndex == $(this).attr('data-order')
            );
        });
    },
    /**
     * [updateCarouselOnResize description]
     * @return {[type]} [description]
     */
    updateCarouselOnResize: function() {
        const isMobile = this.$('.group-carousel-inner').width() < 660;
        carouselActiveIndex = !isMobile
            ? carouselDesktopIndex
            : carouselMobileIndex;

        this.updateCarouselPosition();
    },
    /**
     * [description]
     * @param  {[type]} )   {                 this.updateCarouselPosition();    } [description]
     * @param  {[type]} 300 [description]
     * @return {[type]}     [description]
     */
    calculateLayout: _.debounce(function() {
        if (!this.isActiveView) {
            return;
        }
        console.log('calculateLayout');
        this.updateCarouselOnResize();
    }, 300)
});
