'use strict';
import _ from 'lodash';
import Backbone from 'backbone';

import template from './../templates/header.ejs';
import BaseView from './../views/base';
import CSSView from './css';
import PubSub from '../PubSub';
let styleId;

import headerCssTemplate from './../templates/header-css.ejs';

export default BaseView.extend({
    className: 'view-header',
    /**
     *
     */
    initialize: function(options) {
        _.bindAll(this, 'render');

        options = options || {};
        styleId = this.className + _.uniqueId();
        this.$el.addClass(styleId);
        this.listenTo(this.model, 'change:copy', this.render);
        this.listenTo(
            PubSub,
            'customize-theme',
            _.bind(this.renderedToDom, this)
        );
        this.categories = options.categories;
    },
    /**
     *
     */
    render: function() {
        if (this.CSSView) {
            this.CSSView.remove();
        }
        const cats = this.categories.getActiveCatClassNames();
        this.CSSView = new CSSView({
            model: this.model.get('copy')['customizations'],
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: headerCssTemplate,
            categoryNames: cats
        });

        Backbone.$('head').append(this.CSSView.render().$el);
        this.$el.html(template(this.model.get('copy')));
        return this;
    },
    /**
     *
     */
    renderedToDom: function(cat) {
        let domClass = '';
        const el = document.getElementsByClassName(styleId)[0];
        const classList = el.classList;
        const customClasses = this.categories.getActiveCatClassNames() || [];

        if (_.isUndefined(cat) || cat == 'default') {
            domClass = 'default';
        } else {
            domClass = this.categories.get(cat).get('className');
        }
        //remove any classes from customClasses
        _.each(classList, function(c) {
            if (_.includes(customClasses, c)) {
                classList.remove(c);
            }
        });
        classList.add(domClass);
    }
});
