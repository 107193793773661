import _ from 'lodash';

export const initializeVoteData = ( cmsData ) => {
    const contestants = _.clone(
        _.where(cmsData, { active: '1' }) || [],
        true
    );

    _.each(contestants, (contestant) => {
        contestant.voteCount = 0;
    });

    return contestants;
}