'use strict';
import _ from 'lodash';

import template from './../templates/landing-category-group.ejs';
import BaseView from './../views/base';
import ViewLandingCategory from './landing-category';

export default BaseView.extend({
    className: 'view-landing-category-group',
    tagName: 'div',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .mobile-list-closed': 'listOpen',
            'click .mobile-list-open': 'listClose'
        });
    },
    /**
     *
     */
    initialize: function() {
        _.bindAll(this, 'render');
    },
    /**
     *
     */
    render: function() {
        console.log('render landing-category-group', this.model.toJSON());
        this.$el.html(template(this.model.toJSON()));
        const partialViews = [];
        this.model.get('categoryCollection').forEach(function(item) {
            partialViews.push(
                new ViewLandingCategory({
                    model: _.extend({ parentType: 'group' }, item.toJSON())
                }).render().$el
            );
        });
        this.$('.wrapper-category-list').append(partialViews);
        return this;
    },
    /**
     * [listOpen description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    listOpen: function() {
        if (
            this.$el.width() < 720 &&
            this.$('.category-group-marquee').hasClass('mobile-list-closed')
        ) {
            const toggleState = 'mobile-list-open';
            this.$('.category-group-marquee').alterClass(
                'mobile-list-*',
                toggleState
            );
            this.$('.wrapper-category-list').slideDown('fast');
        }
    },
    /**
     * [listClose description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    listClose: function() {
        if (
            this.$el.width() < 720 &&
            this.$('.category-group-marquee').hasClass('mobile-list-open')
        ) {
            const toggleState = 'mobile-list-closed';
            this.$('.category-group-marquee').alterClass(
                'mobile-list-*',
                toggleState
            );
            this.$('.wrapper-category-list').slideUp('fast');
        }
    }
});
