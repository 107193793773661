'use strict';
import _ from 'lodash';
import template from './../templates/nav-category-group.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';

export default BaseView.extend({
    className: 'view-nav-category-group',
    tagName: 'li',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .category-group-link': 'navActiveGroup'
        });
    },
    /**
     *
     */
    initialize: function() {
        _.bindAll(this, 'render');
        PubSub.on('navActiveGroup', _.bind(this.changeActiveGroup, this));
    },
    /**
     *
     */
    render: function() {
        console.log('render view-nav-category-group', this.model.toJSON());
        this.$el.html(template(this.model.toJSON()));
        return this;
    },
    /**
     * [navActiveGroup description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    navActiveGroup: function(e) {
        e.preventDefault();
        PubSub.trigger('navActiveGroup', this.model.id);
    },
    /**
     * [changeActiveGroup description]
     * @param  {[type]} group_id [description]
     * @return {[type]}          [description]
     */
    changeActiveGroup: function(groupId) {
        this.$el.toggleClass('active', this.model.id === groupId);
    }
});
