module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<div class=\"swipe-wrapper\"> <div class=\"swipe-nav\"> <a href=\"\" class=\"prev\"> <span><i class=\"icon-arrow-left\"></i></span> <em class=\"hidden-xs cat-title\"><%- prevCategoryName %></em> </a> </div> <div class=\"current-identifier\"> <h1><span><%- name %></span></h1> </div> <div class=\"swipe-nav\"> <a href=\"\" class=\"next\"> <em class=\"cat-title hidden-xs\"><%- nextCategoryName %></em> <span><i class=\"icon-arrow-right\"></i></span> </a> </div> </div> <div class=\"swipe-pagination\"> <ol class=\"carousel-indicators\"> <% for(var i=0; i<group_count; i++) { %> <li data-order=\"<%- i %>\" <%- (i===current_index? ' class=\"active\"' : '' ) %>></li> <% } %> </ol> </div> "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"swipe-wrapper\"> <div class=\"swipe-nav\"> <a href=\"\" class=\"prev\"> <span><i class=\"icon-arrow-left\"></i></span> <em class=\"hidden-xs cat-title\">")
    ; __append( prevCategoryName )
    ; __append("</em> </a> </div> <div class=\"current-identifier\"> <h1><span>")
    ; __append( name )
    ; __append("</span></h1> </div> <div class=\"swipe-nav\"> <a href=\"\" class=\"next\"> <em class=\"cat-title hidden-xs\">")
    ; __append( nextCategoryName )
    ; __append("</em> <span><i class=\"icon-arrow-right\"></i></span> </a> </div> </div> <div class=\"swipe-pagination\"> <ol class=\"carousel-indicators\"> ")
    ;  for(var i=0; i<group_count; i++) { 
    ; __append(" <li data-order=\"")
    ; __append( i )
    ; __append("\" ")
    ; __append( (i===current_index? ' class="active"' : '' ) )
    ; __append("></li> ")
    ;  } 
    ; __append(" </ol> </div> ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}