import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
import router from './routes/router';
import ModelCms from 'tscom-cms';
import ModelGeo from 'tscom-geo';
import ModelFacebook from './models/facebook';
import ModelBase from './models/base';
import ModelAuth from './models/auth';
import ModelConnect from './models/vote';
import ModelTurbo from './models/turbo';
import CollectionCms from './collections/cms';
import CollectionVote from './collections/vote';
import CollectionCategory from './collections/category';
import CollectionCategoryGroup from './collections/category-group';
import ModelStorage from './models/storage';
import PubSub from './PubSub';

import ModelIHeart from './models/iheartradio';
// util
import { getQueryParamByName, getDeviceType, checkIfTrue } from './util/helpers';
import { initializeVoteData } from './util/vote';

// constants
import {
    CATEGORY_PREFIX,
    IHEART,
    MODEL_NAMES,
    TURBO_VERSION_CHECK,
    VOTE_ACTION_TYPES,
    VOTE_API_VERSION_CHECK
} from './constants';

('use strict');

/**
 *
 * @param options - {
 * widget_id: 'data/data.json' // REQUIRED string
 * container: $('#myWidget') // REQUIRED string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * modal: $('#wrapper') // string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * uniqueId: 'name-type-namespace-datetime' // string
 * cssUrl: 'http://a1.telesocpe.tv/styles/main.css' // string (absolute path)
 * hashState: false // boolean
 * endpoints: {cms: '//stateapi-prod.votenow.tv/widgets/get'} // object
 * }
 */
function Controller(options) {
    options = options || {};
    options.widget_id = options.widget_id || '71a05079b2b11807';

    options.hashState =
        getQueryParamByName('devmode') === 'true' ||
        options.hashState ||
        false;
    options.endpoints = _.extend(
        {
            cms: 'https://widgetstate.votenow.tv/v1/state/',
            connect: 'https://voteapi.votenow.tv/s2/vote'
        },
        $.type(options.endpoints) === 'object' ? options.endpoints : {}
    );

    const controller = {
        options: options,
        Models: {},
        Collections: {},
        Views: {},
        Routers: {},
        devmode: false,
        /**
         * [delegateVote description]
         * @param  {[type]} model [description]
         * @return {[type]}       [description]
         */
        delegateVote: function (model) {
            console.log('delegate vote', model.toJSON());

            if (!controller.Models.Auth.get('isAuthorized')) {
                console.log('NOT AUTHORIZED');

                PubSub.trigger('user-login');
                PubSub.listenToOnce(
                    controller.Models.Auth,
                    'change:isAuthorized',
                    _.bind(controller.castVote, controller, model)
                );
                return;
            }

            controller.castVote(model);
        },
        /**
         * [authFacebook description]
         * @param  {Function} cb [description]
         * @return {[type]}      [description]
         */
        authFacebook: function (cb) {
            console.log('authFacebook');
            controller.Models.Facebook.isConnected(cb);
        },
        /**
         * [authFacebookSuccess description]
         * @return {[type]} [description]
         */
        authFacebookSuccess: function () {
            console.log('authFacebookSuccess');
            controller.Models.Auth.signInSuccess('facebook');
            PubSub.trigger('authViewClose');
        },
        /**
         *
         */
        authIHeartSuccess: function () {
            console.log('authIHeartSuccess');
            controller.Models.Auth.signInSuccess('iHeartRadio');
        },
        /**
         * [authEmail description]
         * @param  {[type]} email [description]
         * @return {[type]}       [description]
         */
        authEmail: function (email) {
            console.log('authEmail');
            PubSub.listenToOnce(
                controller.Models.Auth,
                'change:isAuthorized',
                function () {
                    PubSub.trigger('authViewClose');
                }
            );
            controller.Models.Auth.signInSuccess('email', email);
        },
        /**
         * [shareFacebook description]
         * @param  {[type]} params [description]
         * @return {[type]}        [description]
         */
        shareFacebook: function (url) {    
            window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
                'popup',
                'width=600, height=400, scrollbars=no'
            );
        },
        /**
         * [castVote description]
         * @param  {[type]} model [description]
         * @return {[type]}       [description]
         */
        castVote: function (model) {
            console.log('castVote', model);
            if (!this.Models.VoteDetail) {
                return;
            }
            const nominee = model.get('id');
            const category = model.get('category_id');
            const total = model.get('votes');
            // @TODO determine how to calculate optin value
            const apiKey = controller.Models.Cms.get('settings').apiKey;
            model.save(
                {
                    apiKey: apiKey,
                    timestamp:
                        controller.Models.Cms.get('ts') ||
                        new Date().getTime().toString(),
                    country: controller.Models.Geo.get('geoheaders').country,
                    state: controller.Models.Geo.get('geoheaders').region,

                    user_id: controller.Models.Auth.get('userID'),
                    email: controller.Models.Auth.get('iHeartEmail'),
                    // fb_age_range: controller.Models.Auth.get('fbAgeRange') || 'U',
                    // fb_email: controller.Models.Auth.get('fbEmail'),
                    // fb_first_name: controller.Models.Auth.get('fbFirstName'),
                    // fb_gender: controller.Models.Auth.get('fbGender'),
                    // fb_last_name: controller.Models.Auth.get('fbLastName'),
                    // fb_locale: controller.Models.Auth.get('fbLocale'),
                    device_type: getDeviceType(),
                    method: IHEART.METHOD_NAME, //(controller.Models.Auth.get('authType')==='email'? 'email': 'fb'),
                    // bucket: "gp"+bucket,
                    category: 'cat' + category,
                    total: total,
                    contestant: nominee,
                    action_type: VOTE_ACTION_TYPES.VOTE
                },
                {
                    success: _.bind(model.saveSuccess, model, function (res) {
                        try {
                            const catVoteString = res[`${CATEGORY_PREFIX}${category}_votestring`];
                            const catVoteHistory = catVoteString ? JSON.parse(catVoteString) : {};
                            const categoryModel = this.Collections.CategoryGroup.getCategoryById(category);
                            this.updateCategoryVotes(categoryModel, catVoteHistory);
                        } catch (e) {
                            // do nothing
                        }

                        PubSub.trigger(
                            'navigate',
                            'thanks/' + category + '-' + nominee
                        );

                    }.bind(this)),
                    error: function () {
                        PubSub.trigger('navigate', 'error/vote');
                    }
                }
            );

        },
        /**
         * [userLogout description]
         * @return {[type]} [description]
         */
        userLogout: function () {
            controller.Models.Auth.deAuthorize();
            controller.Models.User.set({user: null});
        },
        /**
         * [checkWindow description]
         * @param  {[type]} model   [description]
         * @param  {[type]} value   [description]
         * @param  {[type]} options [description]
         * @return {[type]}         [description]
         */
        checkWindow: function () {
            const windowStatus = controller.Models.Cms.get('windowStatus');
            const previousWindowStatus = controller.Models.Cms.previous(
                'windowStatus'
            );

            console.log('DEBUG checkWindow - windowStatus', windowStatus, 'previousWindowStatus', previousWindowStatus);

            switch (windowStatus) {
                /**
                 * OPEN WINDOW
                 */
                case 1:
                    if (previousWindowStatus === 0) {
                        controller.Models.Auth.initializeType();
                        PubSub.trigger('executeRouteHandler', 'landing');
                    }
                    break;
                /**
                 * CLOSED WINDOW
                 */
                case 0:
                default:
                    // Show Closed Window
                    if (previousWindowStatus === 1) {
                        controller.userLogout();
                    }
                    PubSub.trigger('navigate', 'error/window');
                    break;
            }
        },

        /**
         *
         */
        userAgent: {
            android: function () {
                return navigator.userAgent.match(/Android/i);
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad/i);
            },
            isDesktop: function () {
                if (
                    /Android|Mobile|webOS|iPhone|iPad|iPod|Kindle|BlackBerry|PalmOS|PalmSource|Opera Mini|IEMobile|SonyEricsson|smartphone/i.test(
                        navigator.userAgent
                    ) === true
                ) {
                    return;
                }
                return 'pc';
            },
            any: function () {
                return (
                    controller.userAgent.android() ||
                    controller.userAgent.iOS() ||
                    controller.userAgent.isDesktop()
                );
            }
        },

        /**
         *
         */
        _getDevice: function () {
            const qsp = getQueryParamByName('app') || '';
            let device;

            if (qsp === 'web') {
                device = 'nbccom';
            } else if (qsp === 'fb') {
                device = 'facebook';
            } else {
                device = 'unknown';
            }

            device += this.userAgent.any() ? this.userAgent.any() : 'unknown';

            console.log('_getDevice', device.toLowerCase());

            return device.toLowerCase();
        },

        setUpGeo: function () {
            // Akamai Geo Location
            this.Models.Geo = new ModelGeo({
                countries: this.Models.Cms.get('settings').countries
            });
            return this.Models.Geo.fetch();
        },
        setUpFacebook: function () {
            this.Models.Facebook = new ModelFacebook(
                {
                    appId: this.Models.Cms.get('social').fb.id
                },
                {
                    controller: this
                }
            );
        },
        setDevice: function () {
            this.device = this._getDevice();
        },
        setUpIHeartRadio: function () {
            this.Models.IHeartRadio = new ModelIHeart(
                {
                    apiKey: IHEART.API_KEY,
                    contestId: IHEART.CONTEST_ID,
                    accountId: IHEART.ACCOUNT_ID
                },
                {
                    controller: this
                }
            );
        },
        setUpAuth: function () {
            this.Models.Auth = new ModelAuth(
                {},
                {
                    controller: this,
                    callbacks: {
                        storageFetch: this.Models.Storage.fetch.bind(
                            this.Models.Storage
                        ),
                        storageSave: this.Models.Storage.save.bind(
                            this.Models.Storage
                        ),
                        storageDestroy: this.Models.Storage.destroy.bind(
                            this.Models.Storage
                        )
                    }
                }
            );
        },
        setUpHeader: function () {
            this.Models.Header = new ModelBase(
                {
                    copy: _.clone(
                        this.Models.Cms.get('text').view_header,
                        'true'
                    ),
                    uid: options.uniqueId
                },
                {textKey: 'view_header', cms: this.Models.Cms}
            );
        },
        setUpFooter: function () {
            this.Models.Footer = new ModelBase(
                {
                    copy: _.clone(
                        this.Models.Cms.get('text').view_footer,
                        'true'
                    ),
                    uid: options.uniqueId
                },
                {textKey: 'view_footer', cms: this.Models.Cms}
            );
        },
        setUpUser: function () {
            this.Models.User = new ModelBase(
                {
                    copy: _.clone(
                        this.Models.Cms.get('text').view_user,
                        'true'
                    ),
                    uid: options.uniqueId,
                    window: this.Models.Cms.get('windowStatus'),
                    user: this.Models.Auth.get('userName')
                },
                {textKey: 'view_user', cms: this.Models.Cms}
            );
        },
        setUpPage: function () {
            this.Models.Page = new ModelBase(
                {
                    copy: '',
                    uid: options.uniqueId,
                    header: this.Models.Header,
                    footer: this.Models.Footer,
                    categories: this.Collections.Category
                },
                {cms: this.Models.Cms}
            );
        },
        setUpWidgets: function () {
            const cmsModels = new Array();
            const widgets = {
                CmsNominee: {
                    wid:
                        (getQueryParamByName('n_wid').length === 0
                            ? undefined
                            : getQueryParamByName('n_wid')) ||
                        this.Models.Cms.get('settings').nominee_wid,
                    sid:
                        getQueryParamByName('n_sid').length === 0
                            ? undefined
                            : getQueryParamByName('n_sid')
                },
                CmsCategory: {
                    wid:
                        (getQueryParamByName('c_wid').length === 0
                            ? undefined
                            : getQueryParamByName('c_wid')) ||
                        this.Models.Cms.get('settings').category_wid,
                    sid:
                        getQueryParamByName('c_sid').length === 0
                            ? undefined
                            : getQueryParamByName('c_sid')
                },
                CmsCategoryGroup: {
                    wid:
                        (getQueryParamByName('cg_wid').length === 0
                            ? undefined
                            : getQueryParamByName('cg_wid')) ||
                        this.Models.Cms.get('settings').category_group_wid,
                    sid:
                        getQueryParamByName('cg_sid').length === 0
                            ? undefined
                            : getQueryParamByName('cg_sid')
                }
            };

            console.log('loop through all widgets');

            _.forEach(
                widgets,
                function (value, key) {
                    if (!_.isEmpty(value.wid)) {
                        this.Models[key] = new ModelCms(
                            {
                                id: key,
                                wid: value.wid,
                                sid: value.sid,
                                updateFrequency: 10,
                                apiUrl: options.endpoints.cms
                            },
                            {
                                ignoreQSP: true
                            }
                        );
                        cmsModels.push(this.Models[key]);
                    }
                }.bind(this)
            );

            //Add the models to our custom collection.
            const cmsCollection = (this.Collections.Cms = new CollectionCms(
                cmsModels
            ));
            console.log('fetching all components');
            //The collection will call .fetch on each of our models and return a $.Deferred
            return cmsCollection.fetch();
        },

        setUpCategoryAndGroup: function () {            
            const voteLimit = this.Models.Cms.get('settings').vote_limit;

            let categoryGroup = _.clone(
                _.where(this.Models.CmsCategoryGroup.get('data'), {
                    active: '1'
                }) || [],
                true
            );
            let category = _.clone(
                _.where(this.Models.CmsCategory.get('data'), {active: '1'}) ||
                [],
                true
            );
            _.each(category, cat => {
                cat.remainingVotes = voteLimit
            });

            let vote = initializeVoteData(this.Models.CmsNominee.get('data'));
            let mergedData = mergeDataSets(this, categoryGroup, category, vote);
            this.Collections.CategoryGroup = new CollectionCategoryGroup(
                mergedData
            );
            this.Collections.Category = new CollectionCategory(category);

            console.log(
                'defined this.Collections.CategoryGroup',
                this.Collections.CategoryGroup.toJSON()
            );

            categoryGroup = category = vote = mergedData = null;
        },
        setUpListeners: function () {
            this.Models.User.listenTo(
                this.Models.Cms,
                'change:windowStatus',
                function () {
                    this.Models.User.set({
                        window: this.Models.Cms.get('windowStatus')
                    });
                }.bind(this)
            );
            this.Models.User.listenTo(
                this.Models.Auth,
                'change:userName',
                function (authModel, userName) {
                    PubSub.trigger('userNameUpdated', userName);
                    this.Models.User.set({user: userName});
                }.bind(this)
            );

            this.listenTo(
                this.Models.CmsCategoryGroup,
                'change:data',
                updateCategoryGroup.bind(null, this)
            );
            this.listenTo(
                this.Models.CmsCategory,
                'change:data',
                updateCategoryGroup.bind(null, this)
            );
            this.listenTo(
                this.Models.Cms,
                'change:data',
                updateCategoryGroup.bind(null, this)
            );
            this.listenTo(
                this.Models.CmsNominee,
                'change:data',
                updateCategoryGroup.bind(null, this)
            );


            this.listenTo(
              this.Models.Cms,
              'change',
              function(model) {
                if (model.changed.sid || model.changed.windowStatus) {
                  this.checkWindow();
                }
              }.bind(this)
            );

            this.listenTo(PubSub, 'vote', this.delegateVote);
            this.listenTo(PubSub, 'facebookLogin', this.authFacebook);
            this.listenTo(
                PubSub,
                'facebookLoginSuccess',
                this.authFacebookSuccess
            );
            this.listenTo(
                PubSub,
                'iheart-auth-success',
                this.authIHeartSuccess
            );
            this.listenTo(PubSub, 'emailLogin', this.authEmail);
            this.listenTo(PubSub, 'userLogout', this.userLogout);
            this.listenTo(PubSub, 'thanks-view:fb-share', this.shareFacebook);

            //storage
            this.listenTo(
                PubSub,
                'storage-fetch',
                this.Models.Storage.fetch.bind(this.Models.Storage)
            );
            this.listenTo(
                PubSub,
                'storage-save',
                this.Models.Storage.save.bind(this.Models.Storage)
            );
            this.listenTo(
                PubSub,
                'storage-destroy',
                this.Models.Storage.destroy.bind(this.Models.Storage)
            );

            //iheart listerners
            this.listenTo(
                PubSub,
                'iheart-auth-success',
                this.Models.IHeartRadio.getUserProfile.bind(
                    this.Models.IHeartRadio
                )
            );
            this.listenTo(
                PubSub,
                'user-login',
                this.Models.IHeartRadio.login.bind(this.Models.IHeartRadio)
            );
            this.listenTo(
                PubSub,
                'user-signup',
                this.Models.IHeartRadio.signup.bind(this.Models.IHeartRadio)
            );
            this.listenTo(
                PubSub,
                'user-data-fetched',
                this.Models.IHeartRadio.setUserData.bind(
                    this.Models.IHeartRadio
                )
            );
            this.listenTo(
                PubSub,
                'user-anonymous',
                this.Models.IHeartRadio.logout.bind(this.Models.IHeartRadio)
            );

            this.listenTo(
                PubSub,
                'connect:fetch-vote-history',
                this.getCategoryVoteHistory.bind(this)
            );

            this.listenTo(this.Models.Auth, 'change:isAuthorized', (authModel) => {
                const isAuth = authModel.get('isAuthorized');
                if (!isAuth) {
                    this.resetCategoryVotes();
                }
                PubSub.trigger('auth:user-status', isAuth)
            });
        },
        loadConnectModel: function () {
            this.createConnectModels(MODEL_NAMES.CONNECT_VOTE);
            this.createConnectModels(MODEL_NAMES.CONNECT_GET);
        },

        createConnectModels: function (name) {
            var settings = this.Models.Cms.get('settings');
            // Set up Connect model (for GET call, fetching remaining votes)
            this.Models[name] = new ModelConnect(
                {
                    apiKey: settings.apiKey
                },
                {
                    apiUrl: this.options.endpoints.connect,
                    apiSecret: settings.secretKey,
                    version_id: settings.version_id,
                    devmode: this.devmode
                }
            );
            this.Models[name].apiUrl = this.options.endpoints.connect;
            this.Models[name].versionCheck = VOTE_API_VERSION_CHECK;

            this.listenTo(
                this.Models[name],
                'error',
                function (err) {
                    console.error('Connect fetch error', err);
                }.bind(this)
            );
        },

        createTurboModel: function() {
            const settings = this.Models.Cms.get('settings');

            // Set up Connect model (for GET call, fetching remaining votes)
            this.Models.ConnectTurbo = new ModelTurbo(
                {
                    apiKey: settings.turbo_apiKey,
                    voteLimit: parseInt( settings.vote_limit )
                },
                {
                    apiUrl: this.options.endpoints.connect,
                    apiSecret: settings.secretKey,
                    version_id: settings.turbo_version_id,
                    devmode: this.devmode
                }
            );
            this.Models.ConnectTurbo.apiUrl = this.options.endpoints.connect;
            this.Models.ConnectTurbo.versionCheck = TURBO_VERSION_CHECK;
            this.refreshOnNewVotingWindow();
            return this.pollTurboVoteWindow();
        },

        turboTimeout: 0,

        pollTurboVoteWindow: function() {
            const settings = this.Models.Cms.get('settings');
            const snapshotSettings = this.Models.Cms.get('text').view_turbo_banner.polling;
            const windowStatus = checkIfTrue( settings.window_status );
            const enableTurbo = windowStatus && checkIfTrue( snapshotSettings.enable );
            const turboInterval = parseInt( snapshotSettings.interval );
            
            console.log('pollTurboVoteWindow - isTurboEnabled:', enableTurbo);
            
            if( !enableTurbo ) {
                this.turboTimeout = setTimeout( this.pollTurboVoteWindow.bind(this), turboInterval);
                return;
            }

            return this.Models.ConnectTurbo.save()
                .fail( function() {
                    this.turboTimeout = setTimeout( this.pollTurboVoteWindow.bind(this), turboInterval);
                }.bind(this) )
                .then( function( data ) {
                    const settings = this.Models.Cms.get('settings');
                    const defaultVoteLimit = parseInt( settings.vote_limit );
                    const turboVoteLimit = parseInt( settings.turbo_vote_limit );

                    const isTurbo = data.turbo_window;
                    const voteLimit = isTurbo ? turboVoteLimit : defaultVoteLimit;

                    this.Models.ConnectTurbo.set('voteLimit',  voteLimit);
                    this.turboTimeout = setTimeout( this.pollTurboVoteWindow.bind(this), turboInterval);
                }.bind(this) );

        },

        /**
         * Refreshes the page when a new voting window opens. 
         * The timezone set in the CMS must match the timezone of the ruleset window.
         */
        refreshOnNewVotingWindow: function () {
            const settings = this.Models.Cms.get('settings');
            const windowStatus = checkIfTrue( settings.window_status );
            const timeZone = settings.timezone;
            const now = new Date();
            // Convert the current time to the campaign's timezone
            const currentTime = new Date(now.toLocaleString("en-US", {timeZone}));

            // Create a new date object for midnight tonight, in the campaign's timezone
            const nextWindow = new Date(currentTime);
            nextWindow.setHours(24, 0, 0, 0);

            // Calculate the difference in milliseconds until the next voting window
            const timeUntilNextWindowInMilliseconds = nextWindow.getTime() - currentTime.getTime();


            if (!windowStatus) return;

            console.log(`New voting window in ${timeUntilNextWindowInMilliseconds/60000/60} hours`);
           
            setTimeout(function () {
                window.location.reload();
            }, timeUntilNextWindowInMilliseconds);

        },

        routeUser: function () {
            this.Routers.Router = new (router({
                hashState: options.hashState
            }))({
                el: options.container,
                modal: options.modal,
                uid: options.uniqueId,
                controller: this
            });

            if (options.hashState) {
                Backbone.history.start();
            }

            if (!options.hashState) {
                this.Routers.Router.navigate('landing', {trigger: true});
            }

            this.Routers.Router.canScrollToTop = true;
        },
        setUpStorage: function () {
            this.Models.Storage = new ModelStorage();
            window.TSCOMStorage = this.Models.Storage;
        },

        updateCategoryVotes: function (categoryModel, catVoteString) {
            if(!catVoteString) {
                if(categoryModel.get('voteHistory')) {
                    catVoteString = categoryModel.get('voteHistory');
                } else {
                    catVoteString = {};
                }
            }

            const categoryId = categoryModel.get('id');
            const voteLimit = this.Models.ConnectTurbo.get('voteLimit');
            const catTotal = catVoteString[`${CATEGORY_PREFIX}${categoryId}-total`] || 0;
            categoryModel.set('remainingVotes', Math.max(voteLimit - parseInt(catTotal), 0));
            categoryModel.set('voteHistory', catVoteString);

            const voteCollection = categoryModel.get('voteCollection');
            _.each(voteCollection.models, function (voteModel) {
                const key = `${CATEGORY_PREFIX}${voteModel.get('category_id')}-${voteModel.get('id')}`;
                const voteCount = catVoteString[key] ? catVoteString[key] : 0;
                voteModel.set('voteCount', voteCount);
            }.bind(this));
            PubSub.trigger('category-votes-updated', categoryModel.get('remainingVotes'));
        },
        resetCategoryVotes: function () {
            const voteLimit = this.Models.ConnectTurbo.get('voteLimit');
            _.each(this.Collections.CategoryGroup.models[0].get('categoryCollection').models, function (item) {
                item.set('remainingVotes', voteLimit);
                const voteCollection = item.get('voteCollection');

                _.each(voteCollection.models, function (cat) {
                    cat.set('voteCount', 0);
                }.bind(this))

            }.bind(this))

            PubSub.trigger('category-votes-updated');
        },
        /**
         * Hits VoteAPI with action_type `get` to fetch user vote history on specific category
         */
        getCategoryVoteHistory: function(catID){
            const apiKey = controller.Models.Cms.get('settings').apiKey;
            const chain = $.Deferred();

            const params = {
                apiKey: apiKey,
                timestamp: this.Models.Cms.get('ts') ||
                    new Date().getTime().toString(),
                country: this.Models.Geo.get('geoheaders').country,
                state: this.Models.Geo.get('geoheaders').region,
                user_id: this.Models.Auth.get('userID'),
                email: this.Models.Auth.get('iHeartEmail'),
                device_type: getDeviceType(),
                method: IHEART.METHOD_NAME,
                category: `${CATEGORY_PREFIX}${catID}`,
                action_type: VOTE_ACTION_TYPES.GET
            };
            this.Models.ConnectGet.set(params);
            chain.resolve().then(function () {
                return this.Models.ConnectGet.save(params)
                    .then((res) => {
                        if (res.response_code && res.response_code === '20') {
                            try {
                                const catVoteString = res[`${CATEGORY_PREFIX}${catID}_votestring`];
                                var catVoteHistory = catVoteString ? JSON.parse(catVoteString) : {};
                                var categoryModel = this.Collections.CategoryGroup.getCategoryById(catID);

                                this.updateCategoryVotes(categoryModel, catVoteHistory);
                            } catch (e) {
                                // do nothing
                            }

                        } else {
                            PubSub.trigger('category-votes-updated');
                        }


                    });
            }.bind(this));
        }
    };
    _.extend(controller, Backbone.Events);

    const CmsMain = (controller.Models.Cms = new ModelCms(
        {
            wid: options.widget_id,
            apiUrl: options.endpoints.cms,
            updateFrequency: 10
        },
        {controller: controller}
    ));

    if (process.env.IS_LOCAL) {
        window.tsController = controller;
    }

    CmsMain.fetch()
        .then(controller.setUpGeo.bind(controller), function () {
            console.error('ERROR: failed to set up Geo Model');
        })
        .then(controller.setUpFacebook.bind(controller), function () {
            console.error('ERROR: failed to set up Facebook Model');
        })
        .then(controller.setDevice.bind(controller), function () {
            console.error('ERROR: failed to set up device');
        })
        .then(controller.setUpIHeartRadio.bind(controller), function () {
            console.error('ERROR: failed to set up iHeart Model');
        })
        .then(controller.setUpStorage.bind(controller), function () {
            console.error('ERROR: failed to set up Storage Model');
        })
        .then(controller.setUpAuth.bind(controller), function () {
            console.error('ERROR: failed to set up Auth Model');
        })
        .then(controller.loadConnectModel.bind(controller), function () {
            console.error('Error: failed to create Connect Models');
        })
        .then(controller.createTurboModel.bind(controller), function () {
            console.error('Error: failed to create Turbo Model');
        })
        .then(controller.setUpHeader.bind(controller), function () {
            console.error('ERROR: failed to set up Header');
        })
        .then(controller.setUpFooter.bind(controller), function () {
            console.error('ERROR: failed to set up Footer');
        })
        .then(controller.setUpUser.bind(controller), function () {
            console.error('ERROR: failed to set up User');
        })
        .then(controller.setUpWidgets.bind(controller), function () {
            console.error('ERROR: failed to set up Widgets');
        })
        .then(controller.setUpCategoryAndGroup.bind(controller), function () {
            console.error('ERROR: failed to set up Category and Group');
        })
        .then(controller.setUpPage.bind(controller), function () {
            console.error('ERROR: failed to set up Page');
        })
        .then(controller.setUpListeners.bind(controller), function () {
            console.error('ERROR: failed to set up Listeners');
        })
        .then(controller.routeUser.bind(controller), function () {
            console.error('ERROR: failed to set up Router');
        });

    return this;
}

const mergeDataSets = _.debounce(
    function (controller, categoryGroup, category, vote) {
        _.forEach(categoryGroup, function (group) {
            const id = group.id;
            const categoryOptions = _.where(category, {group_id: id});
            _.forEach(categoryOptions, function (cat) {
                const id = cat.id;
                const voteOptions = _.where(vote, {category_id: id});
                if (
                    controller.Models.Cms.get(
                        'text'
                    ).view_vote.randomized.toLowerCase() === 'true'
                ) {
                    voteOptions = _.shuffle(voteOptions);
                }
                cat.voteData = voteOptions;
                cat.voteCollection = new CollectionVote(voteOptions, {
                    controller: controller,
                    devmode: controller.devmode,
                    version_id: controller.Models.Cms.get('settings').version_id
                });
                cat.categoryVoteTotal = 0;
            });
            group.categoryData = categoryOptions;
            group.categoryCollection = new CollectionCategory(categoryOptions);
        });
        return categoryGroup;
    },
    1000,
    {leading: true}
);

const updateCategoryGroup = _.debounce(
    function (controller) {
        console.log('updateCategoryGroup');
        let categoryGroup = _.clone(
            _.where(controller.Models.CmsCategoryGroup.get('data'), {
                active: '1'
            }) || [],
            true
        );
        let category = _.clone(
            _.where(controller.Models.CmsCategory.get('data'), {
                active: '1'
            }) || [],
            true
        );
        let vote = initializeVoteData(controller.Models.CmsNominee.get('data'));

        let mergedData = mergeDataSets(
            controller,
            categoryGroup,
            category,
            vote
        );
        if (
            controller.Collections.CategoryGroup instanceof
            CollectionCategoryGroup
        ) {
            controller.Collections.CategoryGroup.set(mergedData, {
                merge: true
            });
        }
        if (controller.Collections.Category instanceof CollectionCategory) {
            controller.Collections.Category.set(category, {merge: true});
        }
        categoryGroup = category = vote = mergedData = null;
    },
    1000,
    {leading: true}
);

export default Controller;
