'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';

export default Backbone.Model.extend({
    defaults: {
        authType: null,
        isAuthorized: false,
        userName: null,
        userID: null
    },
    /**
     *
     */
    initialize: function(attributes, options) {
        this.controller = options.controller;

        this.on('change:userID', this.defineUser, this);
        this.on('change:authType', this.isEmailUser, this);

        console.log('INIT AUTH MODEL');

        this.initializeType();
        this.callbacks = options.callbacks || {};
    },
    /**
     *
     */
    initializeType: function() {
        // if(this.controller.Models.Facebook.get("status") === null) { // if FB SDK hasn't loaded, add listener
        //     this.controller.Models.Facebook.once('change:status', _.bind(function(){
        //         this.initializeType();
        //     }, this));
        // } else { // once we know the SDK has loaded we can check the window
        //     switch(this.controller.Models.Facebook.get("status")){
        //         case 'connected':
        //             this.setUpFBUser();
        //             this.set({authType: 'facebook'});
        //             break;
        //         case 'not_authorized':
        //             this.set({authType: 'facebook'});
        //             break;
        //         case 'unknown':
        //         default:
        //             this.set({authType: 'email'});
        //             break;
        //     }
        // }
        this.set({ authType: 'iHeartRadio' });
    },
    /**
     *
     */
    signInSuccess: function(type, data) {
        if (type === 'email') {
            if (!_.isNull(this.get('userID')) && this.get('userID') !== data) {
                this.controller.clearUser();
            }
            this.setUpEmailUser(data);
        } else if (type === 'facebook') {
            if ($.type(this.controller.Models.Facebook.get('user')) != 'null') {
                if (
                    !_.isNull(this.get('userID')) &&
                    this.get('userID') !==
                        this.controller.Models.Facebook.get('user').email
                ) {
                    alert('multiple users defined');
                }
                this.setUpFBUser();
            } else {
                // Add listener for when the Facebook user data is available
                this.controller.Models.Facebook.once(
                    'change:user',
                    _.bind(function() {
                        console.log('waiting for user to initialize...');
                        this.signInSuccess('facebook');
                    }, this)
                );
            }
        } else if (type === 'iHeartRadio') {
            if (
                $.type(this.controller.Models.IHeartRadio.get('user')) != 'null'
            ) {
                if (
                    !_.isNull(this.get('userID')) &&
                    this.get('userID') !==
                        this.controller.Models.IHeartRadio.get('user').profileId
                ) {
                    alert('multiple users defined');
                }
                this.setUpIheartUser();
            } else {
                // Add listener for when the Facebook user data is available
                this.controller.Models.IHeartRadio.once(
                    'change:user',
                    _.bind(function() {
                        console.log('waiting for user to initialize...');
                        this.signInSuccess('iHeartRadio');
                    }, this)
                );
            }
        }
    },
    /**
     *
     */
    setUpFBUser: function() {
        console.log('setUpFBUser', this.controller.Models.Facebook.get('user'));
        if ($.type(this.controller.Models.Facebook.get('user')) != 'null') {
            const user = this.controller.Models.Facebook.get('user');
            this.set({
                authType: 'facebook',
                userName: user.first_name,
                userID: user.id,
                isAuthorized: true,
                fbEmail: user.email || null,
                fbFirstName: user.first_name,
                fbLastName: user.last_name,
                fbGender: user.gender,
                fbLocale: user.locale,
                fbAgeRange: user.age_range
            });
        } else {
            // Add listener for when the Facebook user data is available
            this.controller.Models.Facebook.once(
                'change:user',
                _.bind(function() {
                    console.log('waiting for user to initialize...');
                    this.setUpFBUser();
                }, this)
            );
        }
    },
    /**
     *
     */
    setUpEmailUser: function(data) {
        this.set({
            authType: 'email',
            userName: data,
            userID: data,
            isAuthorized: true
        });
    },
    /**
     *
     */
    setUpIheartUser: function() {
        console.log(
            'setUpIheartUser',
            this.controller.Models.IHeartRadio.get('user')
        );
        if ($.type(this.controller.Models.IHeartRadio.get('user')) != 'null') {
            const user = this.controller.Models.IHeartRadio.get('user');
            let userName = '';
            let userNameArr = [];
            //if user.name is defined (note: user.name sometimes is set to the user's email)
            if (user.name) {
                userNameArr = user.name.split(' ');
                userName =
                    userNameArr.length > 1
                        ? userNameArr[0] + ' ' + userNameArr[1].charAt(0)
                        : user.name.split('@')[0];
            } else {
                userName = user.email ? user.email.split('@')[0] : '';
            }
            this.set({
                authType: 'iHeartRadio',
                userName: userName,
                userID: user.profileId,
                isAuthorized: true,
                iHeartEmail: user.email || null,
                iHeartName: user.name || null,
                iHeartGender: user.gender || 'unknown'
            });
        } else {
            // Add listener for when the Facebook user data is available
            this.controller.Models.IHeartRadio.once(
                'change:user',
                _.bind(function() {
                    console.log('waiting for user to initialize...');
                    this.setUpIheartUser();
                }, this)
            );
        }
    },
    /**
     *
     */
    defineUser: function(model, value, options) {
        console.log(
            'defineUser',
            model,
            value,
            options,
            this.changedAttributes(),
            this.previous('userID')
        );
        if ($.type(value) === 'null') {
            // if the user logs out we no longer want their email address in localstorage
            if (!_.isUndefined(this.callbacks.storageFetch('auth_email'))) {
                this.callbacks.storageDestroy('auth_email');
            }
        } else {
            if (
                this.get('authType') === 'email' &&
                _.isUndefined(this.callbacks.storageFetch('auth_email'))
            ) {
                this.callbacks.storageSave('auth_email', this.get('userID'));
            }
        }
    },
    deAuthorize: function() {
        console.log('calling deauthorize', this.get('authType'));

        switch (this.get('authType')) {
            case 'facebook':
                console.log('log out facebook');
                this.controller.Models.Facebook.logout();
                break;
            case 'email':
                console.log('email');
                break;

            case 'iHeartRadio':
                console.log('deAuthorize iHeartRadio');
                this.controller.Models.IHeartRadio.logout();
                break;
            default:
                console.log('nothing');
                // do nothing
                break;
        }

        this.set(this.defaults);
    },
    /**
     *
     */
    isEmailUser: function(model, value, options) {
        console.log('isEmailUser', model, value, options);
        if (
            value === 'email' &&
            !_.isUndefined(this.callbacks.storageFetch('auth_email')) &&
            this.get('userID') === null
        ) {
            console.log(
                'PULLING USERID FROM STORAGE MODEL AND SETTING IT IN THE AUTH MODEL',
                this.callbacks.storageFetch('auth_email')
            );
            this.setUpEmailUser(this.callbacks.storageFetch('auth_email'));
        }
    }
});
