'use strict';
import _ from 'lodash';
import modelFacebook from 'tscom-facebook';

export default modelFacebook.extend({
    defaults: _.extend(_.clone(modelFacebook.prototype.defaults), {
        isManualFlow: false
    }),

    /**
     * SDKs Async Callback, triggered once the script has finished loading
     * Any code that you want to run after the SDK is loaded should be placed within this function and after the call to FB.init.
     * Any kind of JavaScript can be used here, but any SDK functions must be called after FB.init.
     */
    loadedScript: function() {
        /**
         * After loading the JavaScript SDK, call FB.init to initialize the SDK with your app ID.
         * This will let you make calls against the Facebook API.
         */
        FB.init({
            'appId': this.get('appId'),      // App ID from the app dashboard
            'status': true,                  // Check Facebook Login status
            'version': 'v15.0'
        });
        this.set('scriptLoaded', true);

        this.subscribeToEvents();

        /**
         * Check User Login Status when they open this app the first time
         */
        this.checkUserLoginStatus();
    }
});
