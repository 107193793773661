'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';

import template from './../templates/grid.ejs';
import BaseView from './../views/base';
import ViewVoteItem from './vote-item';
import ViewNavCategory from './nav-category';
import ViewNav from './nav';
import ViewUser from './user';
import ViewTurboBanner from './turbo-banner';
import PubSub from './../PubSub';
import CSSView from './css';
import { UI, DEVICE_TYPES } from './../constants';
import { getQueryParamByName } from './../util/helpers';


const NAV_PREVIOUS = UI.NAV_PREVIOUS;
const NAV_NEXT = UI.NAV_NEXT;
const NAV_SEE_ALL = UI.NAV_SEE_ALL;
let styleId;

import cssTemplate from './../templates/grid-css.ejs';

export default BaseView.extend({
    className: 'view-grid',
    userView: null,

    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .footer-control .to-prev': 'toPrev',
            'touchend .footer-control .to-prev': 'toPrev',
            'click .footer-control .to-next': 'toNext',
            'touchend .footer-control .to-next': 'toNext',
            'click .footer-control .to-all': 'toAll',
            'touchend .footer-control .to-all': 'toAll'
        });
    },
    /**
     *
     */
    initialize: function(options) {
        _.bindAll(this, 'render');
        this.turboModel = options.turboModel;
        this.cmsModel = options.cmsModel;
        
        styleId = this.className + _.uniqueId();
        this.$el.addClass(styleId);
        this.listenTo(this.model, 'change:copy', this.render);
        this.authModel = options.authModel;
        this.turboModel = options.turboModel;

        this.listenTo(PubSub, 'category-votes-updated', this.setRemainingVotes.bind(this));
        this.listenTo(PubSub, 'auth:user-status', this.updateHeadline.bind(this));

        // Fetch vote history on init and set auth listener if the user logs out and back in while on the grid view
        this.fetchVoteHistory();
        this.listenTo(this.authModel, 'change:isAuthorized', this.fetchVoteHistory.bind(this));
        this.listenTo(this.turboModel, 'change:voteLimit', this.setRemainingVotes.bind(this));
        this.listenTo(this.turboModel, 'change:turbo_window', this.toggleTurboBanner.bind(this) );
        this.isNative = getQueryParamByName('device_type') === DEVICE_TYPES.NATIVE;

    },        
    toggleTurboBanner: function( ) {
        const turboWindow = this.turboModel.get('turbo_window');
        if( turboWindow ) {
            this.turboView = new ViewTurboBanner({
                copy: this.cmsModel.get('text').view_turbo_banner
            });

            this.$('.user-controls').after( this.turboView.render().$el );

        } else if( this.turboView ) {
            this.turboView.remove();
        }
    },
    /**
     * Emit event to get vote history from current category if the user is authorized
     */
    fetchVoteHistory: function(){
        const isAuth = this.authModel.get('isAuthorized');

        if (isAuth) {
            const currentCategoryId = this.model.get('category');
            PubSub.trigger('connect:fetch-vote-history', currentCategoryId);
        }
    },
    /**
     *
     */
    render: function() {
        console.log('render grid', this.model.get('categoryCollection'));

        if (this.CSSView) {
            this.CSSView.remove();
        }
        const cats = this.model.get('categories').getActiveCatClassNames();
        this.CSSView = new CSSView({
            model: _.extend(
                this.model.get('globalStyles'),
                this.model.get('copy')['customizations']
            ),
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: cssTemplate,
            categoryNames: cats
        });

        Backbone.$('head').append(this.CSSView.render().$el);

        const columnOption = this.model
            .get('copy')
            .column_options.toLowerCase();
        const gridName = 'col-opt-' + columnOption;
        const el = $('<ul/>').addClass(gridName);
        const categoryCollection = this.model.get('categoryCollection');

        const nativeCopy = this.model.get('copy').native_app_subheadline.trim();
        let extra = this.isNative? nativeCopy: '';
        let description = categoryCollection.toJSON().description.replace(/{{VOTE_LIMIT}}/g, this.turboModel.get('voteLimit'));
        
        this.$el.html(
            template(
                _.extend({}, categoryCollection.toJSON(), {
                    copy: this.model.get('copy'),
                    nav: {
                        prevText: NAV_PREVIOUS,
                        nextText: NAV_NEXT,
                        navSeeAllText: NAV_SEE_ALL
                    },
                    isCountVisible: this.authModel.get('isAuthorized'),
                    description: extra+' '+description
                })
            )
        );
        
        this.userView = new ViewUser({ 
            model: this.model.get('user')
        });

        this.$('.user-controls').append(
            this.userView.render().$el
        );
        categoryCollection.get('voteCollection').forEach(
            function(item) {
                el.append(
                    new ViewVoteItem({
                        model: {
                            model: item,
                            copy: _.clone(this.model.get('copy'), true),
                            isCountVisible: this.authModel.get('isAuthorized'),
                            categoryCollection: this.model.get('categoryCollection'),
                            authModel: this.authModel
                        }
                    }).render().$el
                );
            }.bind(this)
        );

        this.$('.voting-grid-options').html(el);

        if (
            this.model.get('copy')['enable_swipe_nav'].toLowerCase() !== 'true'
        ) {
            this.$('.cross-category').html(
                new ViewNavCategory({
                    model: this.model.get('modelNav'),
                    callbacks: this.model.get('callbacks')
                }).render().$el
            );
        } else {
            this.$('.cross-category').html(
                new ViewNav({
                    model: this.model.get('modelNav')
                }).render().$el
            );
        }

        this.$('nav.footer-control').swipe({
            swipeLeft: _.bind(function() {
                console.log('swipeLeft to next');
                this.toNext();
            }, this),
            swipeRight: _.bind(function() {
                console.log('swipeRight to previous');
                this.toPrev();
            }, this)
        });

        return this;
    },
    renderedToDom: function() {
        PubSub.trigger(
            'change-theme',
            this.model.get('modelNav').get('activeCategoryId')
        );

        this.addThemeClassTo('voting-grid-options');
        this.addThemeClassTo('footer-control');
        this.toggleTurboBanner();
    },
    addThemeClassTo: function(className) {
        const cat = this.model.get('modelNav').get('activeCategoryId');

        let domClass = '';
        const el = document.getElementsByClassName(className)[0];
        const classList = el.classList;
        const customClasses =
            this.model.get('categories').getActiveCatClassNames() || [];

        if (_.isUndefined(cat) || cat == 'default') {
            domClass = 'default';
        } else {
            domClass = this.model
                .get('categories')
                .get(cat)
                .get('className');
        }
        //remove any classes from customClasses
        _.each(classList, function(c) {
            if (_.includes(customClasses, c)) {
                classList.remove(c);
            }
        });
        classList.add(domClass);
    },
    /**
     * [toPrev description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    toPrev: function(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const currentCategoryId = this.model.get('category');
        const prevCategory = this.model
            .get('categoryGroupCollection')
            .getPrevCategoryById(currentCategoryId);
        PubSub.trigger('navigate', 'vote/' + prevCategory.id);
        // PubSub.trigger("scrollTop");
        PubSub.trigger('customize-theme', prevCategory.id);
    },
    /**
     * [toNext description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    toNext: function(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const currentCategoryId = this.model.get('category');
        const nextCategory = this.model
            .get('categoryGroupCollection')
            .getNextCategoryById(currentCategoryId);
        PubSub.trigger('navigate', 'vote/' + nextCategory.id);
        // PubSub.trigger("scrollTop");
        PubSub.trigger('customize-theme', nextCategory.id);
    },
    /**
     * [toNext description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    toAll: function(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        PubSub.trigger('navigate', 'landing');
        // PubSub.trigger("scrollTop");
        PubSub.trigger('customize-theme', 'default');
    },
    setRemainingVotes: function () {
        const isAuth = this.authModel.get('isAuthorized');

        const categoryCollection = this.model.get('categoryCollection');
        const remainingVotes = categoryCollection.get('remainingVotes');

        const voteCopy = this.formatCopy(this.model.get('copy').votes_left_copy, remainingVotes);
        const voteCopySingular = this.formatCopy(this.model.get('copy').vote_left_copy, remainingVotes);
        var isSingular = parseInt(remainingVotes, 10) === 1;

        if (isAuth && (remainingVotes !== null && remainingVotes !== undefined)) {
            var countCopy = isSingular? voteCopySingular : voteCopy;
        this.$el.find('.count').html(countCopy);
        } else {
            this.$el.find('.count').html('');
        }

        let description = categoryCollection.toJSON().description.replace(/{{VOTE_LIMIT}}/g, this.turboModel.get('voteLimit'));
        this.$el.find('.vote-cta .description').text( description );

    },
    formatCopy: function(copy, remainingVotes){
        return copy
            .replace('{{DIVIDER}}', '<span class="divider">|</span>')
            .replace('{{VOTE_COUNT}}', remainingVotes);

    },
    updateHeadline: function(isAuth){
        const el = this.$el.find('.count');

        if(!el) return;

        if(isAuth){
            el.removeClass('hidden');
        }else{
            el.addClass('hidden');
        }
    },

    remove: function() {
        Backbone.View.prototype.remove.call( this );

        if( this.turboView) {
            this.turboView.remove();   
        }
        
        if( this.userView ) {
            this.userView.remove();
        }
    }
});
