'use strict';
import _ from 'lodash';

import template from './../templates/page.ejs';
import BaseView from './../views/base';
import ViewFooter from './../views/footer';
import ViewHeader from './../views/header';

export default BaseView.extend({
    className: 'view-page container',
    /**
     *
     */
    initialize: function() {
        _.bindAll(this, 'render');
    },
    /**
     *
     */
    render: function() {
        this.$el.html(template(this.model.get('copy')));
        //test if iframed
        //test for touch events support and if not supported, attach .no-touch class
        this.$el.addClass(
            (top === self ? 'frame-false' : 'frame-true') +
                (!('ontouchstart' in document.documentElement)
                    ? ' no-touch'
                    : '')
        );
        this.$('header').append(
            new ViewHeader({
                model: this.model.get('header'),
                categories: this.model.get('categories')
            }).render().$el
        );
        this.$('footer').append(
            new ViewFooter({
                model: this.model.get('footer'),
                categories: this.model.get('categories')
            }).render().$el
        );

        return this;
    }
});
