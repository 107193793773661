module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<div class=\"item-wrapper\"> <div class=\"item-measure-contents\"> <figure> <% if (model.image.length > 0) { %> <img src=\"<%- model.image %>\"> <% } %> </figure> <div class=\"text-element-wraper\"> <figcaption> <span class=\"name\"><%- model.firstname+\" \"+model.lastname %></span> <span class=\"info\"><%- model.secondaryinfo %></span> <span class=\"vote-count <%- isCountVisible? '': 'hidden'%>\"><%- voteCountCopy %></span> </figcaption> <div class=\"btn-wrapper\"> <button type=\"button\" class=\"btn btn-lg btn-vote\" data-loading-text=\"<%- copy.buttons.submit.loading %>\" data-invalid-text=\"<%- copy.buttons.submit.invalid %>\" data-track=\"<%- copy.buttons.submit.track %>\" data-track-label=\"<%- copy.buttons.submit.track %>:<%- model.id %>\" data-id=\"<%- model.id %>\" <%-( model.eliminated===\"1\" )? ' disabled=\"true\" ' : '' %>> <%- copy.buttons.submit.default %> </button> </div> </div> </div> </div> "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"item-wrapper\"> <div class=\"item-measure-contents\"> <figure> ")
    ;  if (model.image.length > 0) { 
    ; __append(" <img src=\"")
    ; __append( model.image )
    ; __append("\"> ")
    ;  } 
    ; __append(" </figure> <div class=\"text-element-wraper\"> <figcaption> <span class=\"name\">")
    ; __append( model.firstname+" "+model.lastname )
    ; __append("</span> <span class=\"info\">")
    ; __append( model.secondaryinfo )
    ; __append("</span> <span class=\"vote-count ")
    ; __append( isCountVisible? '': 'hidden')
    ; __append("\">")
    ; __append( voteCountCopy )
    ; __append("</span> </figcaption> <div class=\"btn-wrapper\"> <button type=\"button\" class=\"btn btn-lg btn-vote\" data-loading-text=\"")
    ; __append( copy.buttons.submit.loading )
    ; __append("\" data-invalid-text=\"")
    ; __append( copy.buttons.submit.invalid )
    ; __append("\" data-track=\"")
    ; __append( copy.buttons.submit.track )
    ; __append("\" data-track-label=\"")
    ; __append( copy.buttons.submit.track )
    ; __append(":")
    ; __append( model.id )
    ; __append("\" data-id=\"")
    ; __append( model.id )
    ; __append("\" ")
    ; __append(( model.eliminated==="1" )? ' disabled="true" ' : '' )
    ; __append("> ")
    ; __append( copy.buttons.submit.default )
    ; __append(" </button> </div> </div> </div> </div> ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}