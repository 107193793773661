'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
import PubSub from './../PubSub';
import TLDRC from 'tldr-child';
import ViewError from './../views/error';
import ViewLoading from './../views/loading';
import ViewPage from './../views/page';
import ViewModal from './../views/modal';
import ViewLanding from './../views/landing';
import ViewGrid from './../views/grid';
import ViewVoteDetail from './../views/vote-detail';
import ViewThanks from './../views/thanks';
import CSSView from './../views/css';
import ModelBase from './../models/base';
import { windowlessrouter } from 'tscom-util';

export default function routerConstructor(options) {
    const hashState = options.hashState;
    const Router = !hashState ? windowlessrouter : Backbone.Router;

    return Router.extend({
        canScrollToTop: false,
        controller: null,
        currentView: null,
        currentModal: null,
        routes: {
            '': 'landing',
            'landing(/:group)': 'landing',
            'vote/:category': 'vote',
            'vote-detail/:id': 'voteDetail',
            'thanks/:id': 'thanks',
            'auth(/:source)': 'auth',
            'error/:name': 'error',
            'loading/:name': 'loading'
        },
        history: [],
        /**
         *
         */
        initialize: function(options) {
            if (_.isUndefined(options)) {
                return;
            }

            const isIframed = window.top !== window.self;

            this.controller = options.controller;
            this.instanceID = $(options.el || document.body).attr('id');
            this.uid = options.uid;

            if (isIframed) {
                this.pymParentId = 'telescope-app';
                this.pym = new TLDRC.Child({
                    polling: 500
                });
                this.pym.child.id = this.pymParentId;
            } else {
                this.pym = null;
            }

            this.listenTo(
                PubSub,
                'navigate',
                function(route, trigger = true) {
                    const exploded = route.split('/');
                    const path = exploded[0];
                    const subPath = exploded[1];

                    switch (path) {
                        case 'error':
                            return this.error(subPath);

                        case 'vote-detail':
                            return this.voteDetail(subPath);

                        default:
                            if (!trigger && this.currentModal !== null) {
                              this.closeCurrentModal();
                            }
                            this.navigate(route, { trigger });
                    }
                }.bind(this)
            );

            this.listenTo(PubSub, "executeRouteHandler", function (route) {
              const [path, param] = route.split('/');

              if( typeof this[path] === 'function' ) {
                this[path](param);
              }
            }.bind(this));

            this.listenTo(
                PubSub,
                '_back',
                function() {
                    this._back();
                },
                this
            );

            this.on('route', _.bind(this._saveHistory, this));

            this.page = new ViewPage({
                model: this.controller.Models.Page
            }).render();

            this.$body = $(options.el || document.body);
            this.$modalScope = $(options.el || document.body);
            this.$app = this.page.$('div[role="main"]');
            this.$modal = $(
                '<div class="modal"' +
                    (_.isEqual(options.container, options.modal)
                        ? ''
                        : ' id="' + options.uid + 'Modal"') +
                    ' />'
            );

            if (isIframed) {
                this.$modal.addClass('iframed');
            }

            console.log('Router initialize', this.instanceID);

            this.$body.html('').append(this.page.$el);
            this.$modalScope.append(this.$modal);

            $(document).on(
                'click',
                'input[type="button"],input[type="submit"],select,button,a, .login, .logout, span.button',
                _.bind(this.handleViewEvent, this)
            );

            this.css();

            this.$body.disableSelection();

            //listeners
            this.listenTo(PubSub, 'scrollTop', _.bind(this.scrollToTop, this));
        },
        /**
         *
         */
        setCurrentView: function(view, tracking) {
            this.closeCurrentModal();
            this.$app.html(view.render().$el);

            const bodyContext = 'body-' + view.className;
            this.$app.alterClass('body-*', bodyContext);

            // remove old view
            if (this.currentView !== null) {
                this.stopListening( this.controller.Models.ConnectTurbo, 'change:voteLimit' );

                this.currentView.remove();
                this.currentView = null;
            }
            // store current view
            this.currentView = view;

            if (typeof this.currentView.renderedToDom === 'function') {
                this.currentView.renderedToDom();
            }

            if (_.isString(tracking)) {
                this.handlePageView(tracking);
            }
            PubSub.trigger('scrollTop');
            PubSub.trigger('current-view-set');
        },
        /**
         *
         */
        setCurrentModal: function(view, tracking) {
            let prevRoute = null;

            //check if the route is a modal and a vote modal
            if (
                this.currentView == null &&
                (view.id.indexOf('vote-detail') >= 0 ||
                    view.id.indexOf('thanks') >= 0)
            ) {
                prevRoute = view.id.split(':')[0] + '/' + view.id.split(':')[1];
                this.listenToOnce(
                    PubSub,
                    'current-view-set',
                    function() {
                        this.navigate(prevRoute, { trigger: true });
                    }.bind(this)
                );
                this.navigate('vote/' + view.id.split(':')[1].split('-')[0], {
                    trigger: true
                });

                return;

                //if the route is a modal AND not a vote modal or an error modal
            } else if (
                this.currentView == null &&
                view.id.indexOf('error') == -1
            ) {
                prevRoute = view.id.split(':')[0] + '/' + view.id.split(':')[1];
                this.listenToOnce(
                    PubSub,
                    'current-view-set',
                    function() {
                        this.navigate(prevRoute, { trigger: true });
                    }.bind(this)
                );

                this.navigate('landing', { trigger: true });

                return;
            }
            const modalContext = 'modal-has-' + view.className;
            const modal = new ViewModal({
                model: { view: view },
                controller: this.controller
            });
            this.page.$el.alterClass('modal-has-*', modalContext);
            modal.$el.alterClass('modal-has-*', modalContext);
            this.$modal.html(modal.render().$el);
            $('body, html').addClass('has-modal-open');
            PubSub.trigger('scrollTop');

            //when inside iframes, insure iframe height when modal opens
            if (window.self !== window.top) {
                const height = document.documentElement.clientHeight;
                const bodyHeight = height + 'px';
                $('.modal').css('min-height', bodyHeight);
                $('.modal-window').css('min-height', bodyHeight);
            }

            // remove old modal
            if (this.currentModal !== null) {
                if (_.isObject(this.currentModal.model.view)) {
                    this.currentModal.model.view.remove();
                }
                this.currentModal.remove();
                this.currentModal = this.controller.Views.Modal = null;
            }
            // store current modal
            this.currentModal = this.controller.Views.Modal = modal;

            if (typeof this.currentModal.renderedToDom === 'function') {
                this.currentModal.renderedToDom();
            }

            if (
                typeof this.currentModal.model.view.renderedToDom === 'function'
            ) {
                this.currentModal.model.view.renderedToDom();
            }

            if (_.isString(tracking)) {
                this.handlePageView(tracking);
            }

            if (typeof view.renderedToDom === 'function') {
                view.renderedToDom();
            }
        },
        /**
         *
         */
        closedCurrentModal: function() {
            console.log('MODAL HIDDEN!!');
            if (this.currentModal !== null) {
                if (_.isObject(this.currentModal.model.view)) {
                    this.currentModal.model.view.remove();
                }
                this.currentModal.remove();
                this.currentModal = this.controller.Views.Modal = null;
                this.page.$el.alterClass('modal-has-*', '');
                if ($('body, html').hasClass('has-modal-open')) {
                    $('body, html').removeClass('has-modal-open');
                }
            }
            $('.modal').css('min-height', '0');
            $('.modal-window').css('min-height', '0');
        },

        closeCurrentModal: function() {
            console.log('CLOSE CURRENT MODAL');
            if (this.controller.Models.VoteDetail) {
                this.controller.Models.VoteDetail.destroy();
                this.controller.Models.VoteDetail = null;
            }
            PubSub.trigger('reset:vote-item');
            this.closedCurrentModal();
        },
        /**
         * [css description]
         * @return {[type]} [description]
         */
        css: function(model) {
            model = model ||
                this.controller.Models.Cms.get('text')[
                    'global_customizations'
                ] || {
                    font_family: 'FacitWeb',
                    font_import:
                        'http://assets.votenow.tv/fonts/facit/FacitWeb.css'
                };

            this.CSSView = new CSSView({
                model: model,
                namespace: '#' + this.uid + ' ' /*', #' + this.uid + 'Modal'*/
            });

            Backbone.$('head').append(this.CSSView.render().$el);
        },
        /**
         *
         */
        landing: function(group) {
            console.log('ROUTE LANDING');

            if (!this._isOpen('landing' + (group ? '/' + group : ''))) {
                return;
            }
            if (this.controller.Models.Landing) {
                this.controller.Models.Landing.destroy();
                this.controller.Models.Landing = null;
            }
            const model = (this.controller.Models.Landing = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text').view_landing,
                        'true'
                    ),
                    uid: this.uid,
                    user: this.controller.Models.User,
                    categoryGroupCollection: this.controller.Collections
                        .CategoryGroup
                },
                { textKey: 'view_landing', cms: this.controller.Models.Cms }
            ));
            const view = new ViewLanding({
                model: model,
                group: group,
                cmsModel: this.controller.Models.Cms,
                turboModel: this.controller.Models.ConnectTurbo
            });
            this.setCurrentView(view, 'Page View: Landing Page');

            PubSub.trigger('track-page', {
                type: 'landing', //{landing, category, vote, confirm}
                data: null
            });
        },
        /**
         *
         */
        vote: function(category) {
            console.log('ROUTE VOTE');
            if (!this._isOpen('vote/' + category)) {
                return;
            }
            const categories = this.controller.Models.Page.get('categories');
            // find the category model inside of Collections.CategoryGroup
            const matchingCategory = this.controller.Collections.CategoryGroup.getCategoryById(
                category
            );

            // console.log('ROUTE VOTE', matchingCategory);
        
            // trash the vote model if it was previously defined
            if (this.controller.Models.Vote) {
                this.controller.Models.Vote.destroy();
                this.controller.Models.Vote = null;
            }

            if (this.controller.Models.Nav) {
                this.controller.Models.Nav.destroy();
                this.controller.Models.Nav = null;
            }

            const modelNav = (this.controller.Models.Nav = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text').view_nav,
                        'true'
                    ),
                    uid: this.uid,
                    activeCategoryId: category,
                    activeCategoryGroupId: (
                        matchingCategory ||
                        _.sample(
                            this.controller.Collections.CategoryGroup.at(0).get(
                                'categoryCollection'
                            ).models
                        )
                    ).get('group_id'),
                    categoryGroupCollection: this.controller.Collections
                        .CategoryGroup,
                    mobileBreakpoint: this.$body.width() < 750,
                    categories: categories
                },
                { textKey: 'view_nav', cms: this.controller.Models.Cms }
            ));

            const globalStyles = this.controller.Models.Cms.get('text')[
                'global_customizations'
            ];

            // define our data
            const modelVote = (this.controller.Models.Vote = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text').view_vote,
                        'true'
                    ),
                    globalStyles: globalStyles,
                    uid: this.uid,
                    user: this.controller.Models.User,
                    category: category,
                    categoryCollection:
                        matchingCategory ||
                        _.sample(
                            this.controller.Collections.CategoryGroup.at(0).get(
                                'categoryCollection'
                            ).models
                        ),
                    categoryGroupCollection: this.controller.Collections
                        .CategoryGroup,
                    modelNav: modelNav,
                    callbacks: {
                        storageFetch: this.controller.Models.Storage.fetch.bind(
                            this.controller.Models.Storage
                        )
                    },
                    categories: categories
                },
                { textKey: 'view_vote', cms: this.controller.Models.Cms }
            ));
            // define our view
            const view = new ViewGrid({
                model: modelVote,
                authModel: this.controller.Models.Auth,
                turboModel: this.controller.Models.ConnectTurbo,
                cmsModel: this.controller.Models.Cms
            });
            this.setCurrentView(view, 'vote-' + category);

            this.listenTo( this.controller.Models.ConnectTurbo, 'change:voteLimit', this.controller.updateCategoryVotes.bind(this.controller, matchingCategory, undefined) );

            PubSub.trigger('track-page', {
                type: 'category', //{landing, category, vote, confirm}
                data: {
                    cat: matchingCategory.get('name')
                }
            });
        },
        /**
         * [voteDetail description]
         * @param  {String} id : comes is a form of category-id (e.g. 2-A1)
         * @return {[type]}    [description]
         */
        voteDetail: function(id) {
            console.log('ROUTE VOTE DETAIL');
            if (!this._isOpen('vote-detail/' + id)) {
                return;
            }

            // const matchingOption = this.controller.Collections.CategoryGroup.getOptionById(id)
            const matchingOption = this.controller.Collections.CategoryGroup.getOptionByCatAndId(
                id
            );
            const matchingCategory = this.controller.Collections.CategoryGroup.getCategoryById(
                (id.split('-'))[0]
            );
            // console.log('ROUTE VOTE DETAIL', matchingOption);

            if (this.controller.Models.VoteDetail) {
                this.controller.Models.VoteDetail.destroy();
                this.controller.Models.VoteDetail = null;
            }

            const globalStyles = this.controller.Models.Cms.get('text')[
                'global_customizations'
            ];
            const model = (this.controller.Models.VoteDetail = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text').view_vote_detail,
                        'true'
                    ),
                    globalStyles: globalStyles,
                    iheartModel: this.controller.Models.IHeartRadio,
                    categories: this.controller.Models.Page.get('categories'),
                    uid: this.uid,
                    voteOption:
                        matchingOption ||
                        _.sample(
                            this.controller.Collections.CategoryGroup.at(0)
                                .get('categoryCollection')
                                .at(0)
                                .get('voteCollection').models
                        ),
                    currentCategory:
                        matchingCategory ||
                        _.sample(
                            this.controller.Collections.CategoryGroup.at(0).get(
                                'categoryCollection'
                            ).models
                        ),
                    categoryGroupCollection: this.controller.Collections
                        .CategoryGroup
                },
                { textKey: 'view_vote_detail', cms: this.controller.Models.Cms }
            ));
            // define our view
            const view = new ViewVoteDetail({
                model: model,
                id: 'vote-detail:' + id,
                closeModal: this.closeCurrentModal.bind(this),
                turboModel: this.controller.Models.ConnectTurbo
            });
            this.setCurrentModal(view, 'vote-detail-' + id);
            PubSub.trigger('track-page', {
                type: 'vote', //{landing, category, vote, confirm}
                data: {
                    cat: this.controller.Collections.CategoryGroup.getCategoryById(
                        matchingOption.get('category_id')
                    ).get('name'),
                    voteOption: model.get('voteOption').get('omniture_tag')
                }
            });
        },
        /**
         * [thanks description]
         * @param  {[type]} id [description]
         * @return {[type]}    [description]
         */
        thanks: function(id) {
            console.log('ROUTE THANKS');
            if (!this._isOpen('thanks/' + id)) {
                return;
            }

            //const matchingOption = this.controller.Collections.CategoryGroup.getOptionById(id)
            const matchingOption = this.controller.Collections.CategoryGroup.getOptionByCatAndId(
                id
            );

            // console.log('ROUTE THANKS', matchingOption);

            if (this.controller.Models.Thanks) {
                this.controller.Models.Thanks.destroy();
                this.controller.Models.Thanks = null;
            }
            const globalStyles = this.controller.Models.Cms.get('text')[
                'global_customizations'
            ];

            const { vote_count_copy_singular, vote_count_copy_plural } = this.controller.Models.Cms.get('text').view_vote; // eslint-disable-line
            const model = (this.controller.Models.Thanks = new ModelBase(
                {
                    copy: _.extend({}, this.controller.Models.Cms.get('text').view_thanks, {
                        voteCountCopy: vote_count_copy_singular,
                        voteCountCopyPlural: vote_count_copy_plural
                    }),
                    globalStyles: globalStyles,
                    uid: this.uid,
                    categories: this.controller.Models.Page.get('categories'),
                    voteOption:
                        matchingOption ||
                        _.sample(
                            this.controller.Collections.CategoryGroup.at(0)
                                .get('categoryCollection')
                                .at(0)
                                .get('voteCollection').models
                        ),
                    categoryGroupCollection: this.controller.Collections
                        .CategoryGroup,
                    facebook: this.controller.Models.Facebook,
                    callbacks: {
                        fbshare: this.controller.Models.Facebook.share
                    }
                },
                { textKey: 'view_thanks', cms: this.controller.Models.Cms }
            ));
            // define our view
            const view = new ViewThanks({
                model: model,
                id: 'thanks:' + id,
                authModel: this.controller.Models.Auth
            });
            this.setCurrentModal(view, 'thanks-' + id);

            PubSub.trigger('track-page', {
                type: 'confirm', //{landing, category, vote, confirm}
                data: {
                    cat: this.controller.Collections.CategoryGroup.getCategoryById(
                        matchingOption.get('category_id')
                    ).get('name'),
                    voteOption: model.get('voteOption').get('omniture_tag')
                }
            });
        },

        /**
         *
         */
        error: function(name) {
            console.log('ROUTE ERROR', name, this.instanceID);
            let data = this.controller.Models.Cms.get('text')['views_error'][
                name
            ];
            data = _.isObject(data)
                ? data
                : this.controller.Models.Cms.get('text')['views_error'][
                      'generic'
                  ];
            const view = new ViewError({
                model: data,
                className: 'view-error-' + name,
                controller: this.controller,
                id: 'error-' + name,
                closeModal: this.closeCurrentModal.bind(this)
            });

            if (name === 'window') {
                this.setCurrentView(view);
            } else {
                this.setCurrentModal(view);
            }
        },
        /**
         *
         */
        loading: function(name) {
            console.log('ROUTE LOADING', this.instanceID);
            let data = this.controller.Models.Cms.get('text')['views_loading'][
                name
            ];
            data = _.isObject(data)
                ? data
                : this.controller.Models.Cms.get('text')['views_loading'][
                      'generic'
                  ];
            const view = new ViewLoading({
                model: data,
                className: 'view-loading-' + name,
                controller: this.controller,
                id: 'loading:' + name
            });
            this.setCurrentModal(view);
        },
        /**
         *
         */
        _isOpen: function(requestedFragment) {
            console.log('_isOpen', requestedFragment);
            // 1. If we are in one of the allowed regions, AND the window
            //    is open, immedately return true.  In all other cases,
            //    return false.
            // 2. If the region data has not returned yet, CHANGE the url
            //    to show loading, then once the region is available, CHANGE
            //    the url back to what it was at the time of the call
            // 3. If the region data has returned, but is not valid, CHANGE
            //    the url show out-of-geo
            // 4. If the region data has returned and is good, but the window
            //    is not open, CHANGE the url to the closed window page
            requestedFragment = requestedFragment || 'home';

            // is the user out of the geographically acceptable region
            if (_.isNull(this.controller.Models.Geo.get('inRegion'))) {
                this.controller.Models.Geo.once(
                    'change:inRegion',
                    _.bind(function() {
                        this.navigate(requestedFragment, { trigger: true });
                    }, this)
                );
                this.navigate('loading/generic', { trigger: true });
                return false;
            } else if (!this.controller.Models.Geo.get('inRegion')) {
                this.navigate('error/geo', { trigger: true });
                return false;
            }

            // is the window closed?
            if (
                parseInt(
                    this.controller.Models.Cms.get('settings').window_status,
                    10
                ) === 0
            ) {
                this.navigate('error/window', { trigger: true });
                return false;
            }

            return true;
        },
        /**
         *
         */
        _saveHistory: function(route, params) {
            console.log('listener route ' + this.instanceID, route, params);
            this.history.unshift({
                route: route,
                params: params
            });
        },
        /**
         *
         */
        _back: function() {
            if (!hashState) {
                const previous = this.history[0] || {};
                const route = previous.route || 'landing';
                const params = previous.params || [];
                console.log('_back', route, params);
                this[route].apply(this, params);
            } else {
                window.history.back();
            }
        },
        /**
         *
         * @param route
         * @private
         */
        handlePageView: function(tracking) {
            window.dataLayer.push({
                event: 'hashRoutePageView',
                pageUrl: window.location.href,
                pageTitle: tracking
            });
        },
        /**
         *
         * @param e
         * @param eventName
         */
        handleViewEvent: function(e, eventName) {
            console.log('handleViewEvent', e, eventName);
            let category = '';
            let action = '';
            const tag = _.isString(e) ? '' : e.currentTarget.nodeName;
            const el = _.isString(e) ? '' : $(e.currentTarget);
            const data =
                    _.isString(eventName) && eventName.length > 0
                        ? eventName
                        : el.is('[data-track]')
                        ? el.attr('data-track')
                        : undefined;

            if (_.isUndefined(data)) {
                return;
            }
            switch (tag.toLowerCase()) {
                case 'a':
                case 'span':
                    category = 'link_click';
                    action = el.text() + ' - ' + el.prop('href');
                    break;
                case 'button':
                    category = 'button_click';
                    action = el.text();
                    break;
                case 'input':
                    category = 'button_click';
                    action = el.val() + ' - ' + el.prop('type');
                    break;
                default:
                    category = 'button_click';
                    action = el.val() + ' - ' + el.prop('type');
            }

            category = _.isString(eventName) ? 'custom' : category;
            action = !_.isUndefined(data) ? data : action;

            window.dataLayer.push({
                event: category,
                custom_action: action
            })
        },
        /**
         * scrollToTop
         * @return {undefined}
         */
        scrollToTop: function() {
            if (!this.canScrollToTop) {
                return;
            }

            if (top !== self) {
                const scrollToTopOffset = this.controller.Models.Cms.get('text')
                    .iframe.scroll_to_top_offset;
                const payload = JSON.stringify({
                    y: Number(scrollToTopOffset) || 0
                });

                this.pym.sendMessage('scrollToChildPosition', payload);
            }

            window.scrollTo(0, 0);
        }
    });
};
