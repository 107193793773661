'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';

import template from './../templates/landing.ejs';
import BaseView from './../views/base';
import ViewLandingCategoryGroup from './landing-category-group';
import ViewLandingCategory from './landing-category';
import ViewUser from './user';
import ViewTurboBanner from './turbo-banner';
import CSSView from './css';
import cssTemplate from './../templates/landing-css.ejs';

import { getQueryParamByName } from './../util/helpers';
import { DEVICE_TYPES } from './../constants';

let styleId;
let groupId;

export default BaseView.extend({
    className: 'view-landing',
    turboView: null,
    /**
     *
     */
    initialize: function(options) {
        _.bindAll(this, 'render');
        groupId = options.group;
        styleId = this.className + _.uniqueId();
        this.turboModel = options.turboModel;
        this.cmsModel = options.cmsModel;

        this.$el.addClass(styleId);
        this.listenTo(this.model, 'change:copy', this.render);

        this.itemHeight = 0;
        Backbone.$(window).on(
            'resize',
            _.bind(function() {
                this.itemHeight = 0;
            }, this)
        );

        this.listenTo( this.turboModel, 'change:turbo_window', this.toggleTurboBanner.bind(this) );

        this.isNative = getQueryParamByName('device_type') === DEVICE_TYPES.NATIVE;
    },
    toggleTurboBanner: function( ) {
        const turboWindow = this.turboModel.get('turbo_window');
        if( turboWindow ) {
            this.turboView = new ViewTurboBanner({
                copy: this.cmsModel.get('text').view_turbo_banner
            });

            this.$('.user-controls').after( this.turboView.render().$el );

        } else if( this.turboView ) {
            this.turboView.remove();
        }
    },
    /**
     *
     */
    render: function() {
        if (this.CSSView) {
            this.CSSView.remove();
        }

        this.CSSView = new CSSView({
            model: this.model.get('copy')['customizations'],
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: cssTemplate
        });

        Backbone.$('head').append(this.CSSView.render().$el);

        this.$el.html(template(_.extend(this.model.get('copy'), {
            isNative: this.isNative
        })));

        this.userView = new ViewUser({ 
            model: this.model.get('user')
        });

        this.$('.user-controls').append(
            this.userView.render().$el
        );

        if (groupId) {
            return this.renderByGroup();
        }

        return this.renderAll();
    },
    renderedToDom: function() {
        this.toggleTurboBanner();
    },
    /**
     * [renderByGroup description]
     * @return {[type]} [description]
     */
    renderByGroup: function() {
        const partialViews = [];
        const matchingCategory = false;
        this.model.get('categoryGroupCollection').forEach(
            _.bind(function(item) {
                if (matchingCategory) return this;
                matchingCategory = item.id === groupId;
                if (matchingCategory) {
                    item.get('categoryCollection').forEach(function(item) {
                        partialViews.push(
                            new ViewLandingCategory({
                                model: _.extend(
                                    { parentType: 'single' },
                                    item.toJSON()
                                )
                            }).render().$el
                        );
                    });
                    this.$('.wrapper-landing-categories').append(
                        $('<ul class="single-category">').append(partialViews)
                    );
                }
            }, this)
        );
        if (matchingCategory === false) {
            this.$('.wrapper-landing-categories').html(
                $(
                    '<div class="alert alert-danger" role="alert"><strong>No Results</strong> Please try searching for a different group.</div>'
                )
            );
        }
        return this;
    },
    /**
     * [renderAll description]
     * @return {[type]} [description]
     */
    renderAll: function() {
        const partialViews = [];
        const leftPartialViews = [];
        const rightPartialViews = [];
        if (this.model.get('categoryGroupCollection').length > 1) {
            this.model
                .get('categoryGroupCollection')
                .forEach(function(item) {
                    partialViews.push(
                        new ViewLandingCategoryGroup({ model: item }).render()
                            .$el
                    );
                });
            this.$('.wrapper-landing-categories').append(partialViews);
        } else if (this.model.get('categoryGroupCollection').length === 1) {
            this.model
                .get('categoryGroupCollection')
                .at(0)
                .get('categoryCollection')
                .forEach(function(item, index, list) {
                    //ICV-137: creating 2 columns
                    if (index < Math.ceil(list.length / 2)) {
                        leftPartialViews.push(
                            new ViewLandingCategory({
                                model: _.extend(
                                    { parentType: 'single' },
                                    item.toJSON()
                                )
                            }).render().$el
                        );
                    } else {
                        rightPartialViews.push(
                            new ViewLandingCategory({
                                model: _.extend(
                                    { parentType: 'single' },
                                    item.toJSON()
                                )
                            }).render().$el
                        );
                    }
                });
            this.$('.wrapper-landing-categories').append(
                $('<ul class="single-category left">').append(leftPartialViews)
            );
            this.$('.wrapper-landing-categories').append(
                $('<ul class="single-category right">').append(
                    rightPartialViews
                )
            );
        }
        return this;
    },

    remove: function() {
        Backbone.View.prototype.remove.call( this );
        if( this.userView ) {
            this.userView.remove();
        }

        if( this.turboView ) {
            this.turboView.remove();
        }
    }
});
