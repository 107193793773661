'use strict';
import _ from 'lodash';
import Backbone from 'backbone';
import template from './../templates/thanks.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';
import CSSView from './css';
import cssTemplate from './../templates/thanks-css.ejs';
let styleId;

export default BaseView.extend({
    className: 'view-thanks',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .close': 'close',
            'click .btn-thanks-twitter': 'toTwitter',
            'click .share-btn-twitter': 'prepTwitterShare',
            'click .btn-thanks-next': 'toNext',
            'click .btn-thanks-all': 'toAll',
            'click .btn-thanks-again': 'toAgain',
            'click .share-btn-facebook': 'shareToFacebook'
        });
    },
    /**
     *
     */
    initialize: function(options) {
        _.bindAll(this, 'render');

        styleId = this.className + _.uniqueId();
        this.$el.addClass(styleId);
        this.authModel = options.authModel;

        this.listenTo(this.model, 'change:copy', this.render);

        this.listenTo(this.authModel, 'change:isAuthorized', this.generateVoteCountCopy.bind(this));
        this.listenTo(PubSub, 'category-votes-updated', this.generateVoteCountCopy.bind(this));

    },
    /**
     * [renderedToDOM description]
     * @return {[type]} [description]
     */
    renderedToDom: function() {
        this.generateVoteCountCopy();

        const voteOption = this.model.get('voteOption').toJSON();

        this.addClassTheme(voteOption.category_id);
    },
    /**
     *
     */
    addClassTheme: function(cat) {
        let domClass = '';
        const el = document.getElementsByClassName(styleId)[0];
        const classList = el.classList;
        const customClasses =
            this.model.get('categories').getActiveCatClassNames() || [];

        if (_.isUndefined(cat) || cat == 'default') {
            domClass = 'default';
        } else {
            domClass = this.model
                .get('categories')
                .get(cat)
                .get('className');
        }
        //remove any classes from customClasses
        _.each(classList, function(c) {
            if (_.includes(customClasses, c)) {
                classList.remove(c);
            }
        });
        classList.add(domClass);
    },
    /**
     *
     */
    render: function() {
        if (this.CSSView) {
            this.CSSView.remove();
        }

        const cats = this.model.get('categories').getActiveCatClassNames();

        this.CSSView = new CSSView({
            model: _.extend(
                this.model.get('globalStyles'),
                this.model.get('copy')['customizations']
            ),
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: cssTemplate,
            categoryNames: cats
        });
        Backbone.$('head').append(this.CSSView.render().$el);

        const voteOption = this.model.get('voteOption').toJSON();
        const categoryName = this.model
            .get('categoryGroupCollection')
            .getCategoryName(voteOption.category_id);
        const category = this.model
            .get('categoryGroupCollection')
            .getCategoryById(voteOption.category_id);

        console.log('thanks render', voteOption);
        const contestantName = [voteOption.firstname.trim(), voteOption.lastname.trim()].join(' ');

        this.$el.html(
            template(
                _.extend(voteOption, {
                    category_id: voteOption.category_id,
                    contestantName: contestantName,
                    categoryName: categoryName,
                    copy: this.model.get('copy'),
                    video_uid: this.video_uid,
                    sharingTwitter: encodeURIComponent(
                        this.model
                            .get('copy')
                            .sharing.twitter.replace(
                                '{{NAME}}',
                                voteOption.firstname + ' ' + voteOption.lastname
                            )
                            .replace('{{CATEGORY}}', categoryName)
                    ),
                    twitterVoteText: encodeURIComponent(
                        this.model
                            .get('copy')
                            .buttons.twitter.text_template.replace(
                                '{{NAME}}',
                                voteOption.firstname + ' ' + voteOption.lastname
                            )
                            .replace('{{CATEGORY}}', categoryName)
                    ),
                    remainingVotes: category.get('remainingVotes'),
                })
            )
        );

        return this;
    },
    /**
     * [close description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    close: function(e) {
        console.log('close');
        e.preventDefault();
        const voteOption = this.model.get('voteOption');
        // prevent the
        this.$el.focus();
        PubSub.trigger('navigate', 'vote/' + voteOption.get('category_id'), false);
    },
    /**
     * [toTwitter description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    toTwitter: function(e) {
        e.preventDefault();
        //http://twitter.com/intent/tweet?text=<%= twitterVoteText
        const top = (window.innerHeight - 200) / 2;
        const voteOption = this.model.get('voteOption').toJSON();

        const text = encodeURIComponent(
            this.model
                .get('copy')
                .buttons.twitter.text_template.replace(
                    '{{NAME}}',
                    voteOption.share_description
                )
                .replace('{{CATEGORY}}', voteOption.hashtag)
        );
        const left = (window.innerWidth - 200) / 2;
        window.open(
            'https://twitter.com/intent/tweet/?text=' + text,
            'intent',
            'width=500, height=420, top=' + top + ', left=' + left
        );
    },
    /**
     * [prepTwitterShare description]
     * @return {[type]} [description]
     */
    prepTwitterShare: function(e) {
        e.preventDefault();
        //http://twitter.com/intent/tweet?text=<%= sharingTwitter %>
        const top = (window.innerHeight - 200) / 2;
        const voteOption = this.model.get('voteOption').toJSON();

        const text = encodeURIComponent(
            this.model
                .get('copy')
                .sharing.twitter.replace(
                    '{{NAME}}',
                    voteOption.share_description
                )
                .replace('{{CATEGORY}}', voteOption.hashtag)
        );
        const left = (window.innerWidth - 200) / 2;
        window.open(
            'https://twitter.com/intent/tweet/?text=' + text,
            'intent',
            'width=500, height=420, top=' + top + ', left=' + left
        );
    },
    /**
     * [toNext description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    toNext: function(e) {
        e.preventDefault();

        const voteOption = this.model.get('voteOption');
        const currentCategoryId = voteOption.get('category_id');

        this.$el.focus();
        const nextCategory = this.model
            .get('categoryGroupCollection')
            .getNextCategoryById(currentCategoryId);
        // console.log('to next', nextCategory.id);

        PubSub.trigger('navigate', 'vote/' + nextCategory.id);
        PubSub.trigger('customize-theme', nextCategory.id);
    },
    /**
     * [toAll description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    toAll: function(e) {
        e.preventDefault();
        this.$el.focus();
        PubSub.trigger('navigate', 'landing');
        PubSub.trigger('customize-theme', 'default');
    },
    /**
     * [toAgain description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    toAgain: function(e) {
        e.preventDefault();
        const voteOption = this.model.get('voteOption');
        this.$el.focus();
        PubSub.trigger('navigate', 'vote/' + voteOption.get('category_id'), false);
    },
    /**
     * [shareToFacebook description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    shareToFacebook: function(e) {
        e.preventDefault();
        const voteOption = this.model.get('voteOption').toJSON();
        const url = `https://${window.location.hostname}/share?contestant=${voteOption.id}&category=${voteOption.category_id}`;
        
        PubSub.trigger('thanks-view:fb-share', url);
    },
    generateVoteCountCopy: function(){
        const voteOption = this.model.get('voteOption').toJSON();
        const isAuth = this.authModel.get('isAuthorized');

        const count = parseInt(voteOption.total, 10) || parseInt(voteOption.voteCount, 10);
        let result = '';

        if (!isAuth || count === 0) {
          this.$el.find('.total-votes').html(result);
          return;
        }

        if (count > 1) {
            result = `${count} ${this.model.get('copy').voteCountCopyPlural || 'VOTES'}`;
        } else {
            result = `${count} ${this.model.get('copy').voteCountCopy || 'VOTE'}`;
        }

        this.$el.find('.total-votes').html(result);
    },
    handleUpdatedVotes: function(){
        this.render();
        const voteOption = this.model.get('voteOption').toJSON();
        const cat = this.model
            .get('categoryGroupCollection')
            .getCategoryById(voteOption.category_id);


        if (cat.get('remainingVotes') === 0) {
            this.$('.btn-thanks-again').prop('disabled', true);
        }
    }
});
