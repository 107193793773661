'use strict';
import _ from 'lodash';
import template from './../templates/modal.ejs';
import BaseView from './../views/base';

export default BaseView.extend({
    tagName: 'div',
    className: 'view-modal',
    /**
     *
     */
    initialize: function() {
        _.bindAll(this, 'render');
    },
    /**
     *
     */
    render: function() {
        this.$el.html(template());
        //test for touch events support and if not supported, attach .no-touch class
        this.$el.addClass(
            !('ontouchstart' in document.documentElement) ? 'no-touch' : ''
        );
        this.$('.modal-content').append(
            _.isObject(this.model.view)
                ? this.model.view.render().$el
                : this.model.view
        );
        if (window.innerWidth <= 400 && navigator.userAgent.match(/Android/i)) {
            this.$('.modal-content > div').css({
                width: window.innerWidth + 'px'
            });
        }
        return this;
    }
});
