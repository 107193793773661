'use strict';
import _ from 'lodash';
import template from './../templates/loading.ejs';
import BaseView from './../views/base';

export default BaseView.extend({
    tagName: 'div',
    className: 'view-loading',
    /**
     *
     */
    initialize: function() {
        _.bindAll(this, 'render');
    },
    /**
     *
     */
    render: function() {
        console.log('loading view', _.result(this, 'events'));
        this.$el.html(template(this.model));
        return this;
    }
});
