'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
import modelConnect from 'tscom-connect';
import { TURBO_VERSION_CHECK, removeEmptyProperties } from './../constants';
import { checkIfTrue } from '../util/helpers';

function defineProtocolForCorsXhr(url) {
    if (url.search(/^http[s]?\:\/\//) === -1) {
        url = (!$.support.cors ? document.location.protocol : 'https:') + url;
    }
    return url;
}

export default modelConnect.extend({
    url: '',
    defaults: function() {
        return {
            apiKey: 'categoryvote',
            timestamp: new Date().getTime().toString(), // 13 digit
        };
    },
    apiUrl:
        'https://voteapi.votenow.tv/s2/vote',
    versionCheck: TURBO_VERSION_CHECK,
    xhr: null,
    saveParams: [
        'apiKey',
        'timestamp'
    ],
    /**
     *
     */
    initialize: function(attrs, options) {

        this.devmode = options.devmode;

        this.secretKey = options.secretKey;
        this.version_id = options.version_id;

        this.apiUrl = defineProtocolForCorsXhr(options.apiUrl || this.apiUrl);
    },
    /**
     * @desc override tscom-connect fetch with Backbone default
     * @param options
     * @returns {*}
     */
    fetch: function() {
        console.warn('The vote model does not support "fetch"');
    },
    /**
     * @desc override tscom-connect fetch with Backbone default
     * @param attrs
     * @param options
     * @returns {*|Session}
     */
    save: function() {
        return Backbone.Model.prototype.save.apply(this, arguments);
    },
    /**
     * destroy - not supported
     */
    destroy: function() {
        console.warn('The vote model does not support "destroy"');
    },
    /**
     * @desc Override sync method - if devmode or options.ajaxSync===false, write to localStorage, otherwise make requests to the Vote API via default Backbone.sync
     *
     * @param {string} crud - Will be one of: 'create', 'read', 'update', 'destroy'.  We use the value to determine action type.
     * @param {Backbone.Model} model - A reference to this model.
     * @param {object} options - A JS object with options for $.ajax.
     */
    sync: function(crud, model, options) {
        console.log(
            'turbo model sync',
            crud,
            model,
            options,
            this.devmode === false
        );
        options = _.extend(
            {
                ajaxSync: this.devmode === false
            },
            options || {}
        );
        let qsp = removeEmptyProperties(_.pick(model.toJSON(), this.saveParams));
        qsp = this.paramsLiteralToQsp(qsp)
        
        const b64hash = this.qspToHash(qsp);
        
        const params = {
            url: this.apiUrl + '?' + qsp,
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            data: 'Authorization=' + encodeURIComponent(b64hash)
        };

        _.extend(params, options);

        this.xhr = Backbone.sync(crud, model, params)

        return this.xhr;
    },

    parse: function( data ) {
      if( data ) {
        data.turbo_window = checkIfTrue( data.turbo_window );
      }

      return data;
    },

    abortRequest: function() {
        if (_.isFunction(this.xhr.abort)) this.xhr.abort();
    }
});
