'use strict';
import _ from 'lodash';


export const TRUTHY_STRINGS = ['true', 'yes', 'active', '1'];

// constants
const ALLOWED_PROPERTIES = [ 'number', 'boolean' ];

/**
 * removeEmptyProperties
 * remove property from object if its value is empty string or undefined
 * @param {object} obj
 * @return {object}
 */
export const removeEmptyProperties = ( obj ) => _.reduce( obj, ( memo, value, key ) => {

    if ( value || ALLOWED_PROPERTIES.indexOf( typeof value ) >= 0 ) {
        memo[ key ] = value;
    }

    return memo;

}, {});
