import Backbone from 'backbone';

export default Backbone.Model.extend({
    initialize: function(options) {
        options = options || {};
        if (options.storageMethod && options.storageMethod.match(/cookie/i)) {
            this._storeMethod = 'Cookie';
        } else {
            try {
                localStorage.setItem('local_storage_test', 'test');
                localStorage.removeItem('local_storage_test');
                this._storeMethod = 'LocalStorage';
            } catch (e) {
                //use custom method if localStorage and Cookie fails
                this._storeMethod = 'CustomStorage';
            }
        }
    },

    /* Exposed Functions 
    -------------------------*/
    save: function(name, value, days) {
        this['_set' + this._storeMethod](name, value, days);
    },

    fetch: function(name) {
        return this['_read' + this._storeMethod](name);
    },

    destroy: function(name) {
        this['_delete' + this._storeMethod](name);
    },

    /* COOKIE Functions 
    -------------------------*/
    _setCookie: function(name, value, days) {
        days = (days || 7) * 24 * 60 * 60 * 1000;
        const expirationDate = new Date(Date.now() + days);
        document.cookie =
            name +
            '=' +
            value +
            '; expires=' +
            expirationDate.toGMTString() +
            '; path=/';
    },

    _readCookie: function(name) {
        const regEx = new RegExp(name + '=[^;]+(;|$)');
        const result = document.cookie.match(regEx);
        if (result) {
            return result[0].split(/[;=]/g)[1];
        }
        return null;
    },

    _deleteCookie: function(name) {
        this._setCookie(name, '', -1);
    },

    /* LOCALSTORAGE Functions 
    -------------------------*/
    _setLocalStorage: function(name, value) {
        localStorage.setItem(name, value);
    },

    _readLocalStorage: function(name) {
        return localStorage.getItem(name);
    },

    _deleteLocalStorage: function(name) {
        localStorage.removeItem(name);
    },

    /* CustomStorage Functions */
    _setCustomStorage: function(name, value) {
        const tmp = {};
        tmp[name] = value;
        this.set(tmp);
    },

    _readCustomStorage: function(name) {
        return this.get(name);
    },

    _deleteCustomStorage: function(name) {
        this.unset(name);
    }
});
