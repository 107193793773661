'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
import modelConnect from 'tscom-connect';
import PubSub from './../PubSub';
import { VOTE_API_VERSION_CHECK, removeEmptyProperties } from './../constants';

function defineProtocolForCorsXhr(url) {
    if (url.search(/^http[s]?\:\/\//) === -1) {
        url = (!$.support.cors ? document.location.protocol : 'https:') + url;
    }
    return url;
}

export default modelConnect.extend({
    url: '',
    defaults: function() {
        return {
            // vote data
            firstname: '',
            lastname: '',
            active: '0',
            secondaryinfo: '',
            thirdinfo: '',
            bio: '',
            video: '',
            eliminated: '0',
            moreinfoURL: '',
            id: '',
            image: '',
            image_retina: '',
            name: '',
            category_id: '',
            // vote API requirements
            apiKey: 'categoryvote',
            timestamp: new Date().getTime().toString(), // 13 digit
            // optin: "", // 1|0
            country: '',
            state: '',
            user_id: '',
            method: '', // fb|email
            //bucket: "", // number
            category: '', // alphanumeric e.g. cat1, cat9
            contestant: '', // alphanumeric - single letter followed by number(s) i.e. A1
            email: '',
            device_type: '',
            total: '',
            action_type: ''
        };
    },
    apiUrl:
        'https://voteapi.votenow.tv/s2/vote',
    versionCheck: VOTE_API_VERSION_CHECK,
    xhr: null,
    saveParams: [
        'apiKey',
        'timestamp',
        // ,'optin'
        'country',
        'state',
        'user_id',
        'method',
        //,'bucket'
        'category',
        'contestant',
        'fb_age_range',
        'fb_email',
        'fb_first_name',
        'fb_gender',
        'fb_last_name',
        'fb_locale',
        'email',
        'device_type',
        'total',
        'action_type'
    ],
    /**
     *
     */
    initialize: function(attrs, options) {

        this.devmode = options.devmode;

        this.secretKey = options.secretKey;
        this.version_id = options.version_id;

        this.apiUrl = defineProtocolForCorsXhr(options.apiUrl || this.apiUrl);
    },
    /**
     * @desc override tscom-connect fetch with Backbone default
     * @param options
     * @returns {*}
     */
    fetch: function() {
        console.warn('The vote model does not support "fetch"');
    },
    /**
     * @desc override tscom-connect fetch with Backbone default
     * @param attrs
     * @param options
     * @returns {*|Session}
     */
    save: function() {
        return Backbone.Model.prototype.save.apply(this, arguments);
    },
    /**
     * destroy - not supported
     */
    destroy: function() {
        console.warn('The vote model does not support "destroy"');
    },
    /**
     * @desc Override sync method - if devmode or options.ajaxSync===false, write to localStorage, otherwise make requests to the Vote API via default Backbone.sync
     *
     * @param {string} crud - Will be one of: 'create', 'read', 'update', 'destroy'.  We use the value to determine action type.
     * @param {Backbone.Model} model - A reference to this model.
     * @param {object} options - A JS object with options for $.ajax.
     */
    sync: function(crud, model, options) {
        console.log(
            'vote model sync',
            crud,
            model,
            options,
            this.devmode === false
        );
        options = _.extend(
            {
                ajaxSync: this.devmode === false
            },
            options || {}
        );
        let qsp = removeEmptyProperties(_.pick(model.toJSON(), this.saveParams));
        qsp = this.paramsLiteralToQsp(qsp)
        
        const b64hash = this.qspToHash(qsp);
        
        const params = {
            url: this.apiUrl + '?' + qsp,
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            data: 'Authorization=' + encodeURIComponent(b64hash)
        };

        _.extend(params, options);

        this.xhr = Backbone.sync(crud, model, params)
            .done(_.bind(this.syncSuccess, this, crud))
            .fail(this.syncError);

        return this.xhr;
    },
    /**
     *
     * @param response
     */
    saveSuccess: function(callback, model, response, options) {
        console.log('model.vote saveSuccess', model, response, options);
        // if we don't get any valid data
        if (!(_.isObject(response) && _.size(response) > 0)) {
            PubSub.trigger('navigate', 'error/vote');
            return false;
        }
        // spoorfing success
        if (this.devmode) {
            response.response_code = '20';
        }
        switch (response.response_code || '') {
            case '20':
                callback(response);
                // this.save({vote: true},{ajaxSync: false}); // write to localStorage
                break;
            case '21':
                PubSub.trigger('navigate', 'error/overlimit');
                // this.save({vote: false},{ajaxSync: false}); // write to localStorage
                break;
            default:
                PubSub.trigger('navigate', 'error/vote');
                // this.save({vote: false},{ajaxSync: false}); // write to localStorage
                break;
        }
    },
    /**
     *
     * @param response
     */
    syncSuccess: function(crud, response) {
        console.log('syncSuccess model.vote', crud, response);
    },
    /**
     *
     * @param response
     */
    syncError: function(jqXHR, textStatus, errorThrown) {
        console.log('model.vote syncError', jqXHR, textStatus, errorThrown);
    },
    abortRequest: function() {
        if (_.isFunction(this.xhr.abort)) this.xhr.abort();
    }
});
