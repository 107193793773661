module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<%- namespace %> .wrapper-text { <%- text_display==='false'? 'display: none;' : '' %> } <%- namespace %> .category-group-name { <%- group_text_color.length>0? 'color: '+group_text_color+';' : '' %> } <%- namespace %> .category-name { <%- category_text_color.length>0? 'color: '+category_text_color+';' : '' %> } <%- namespace %>.view-landing .view-landing-category-group .category-group-marquee { <%- group_backgroud_color.length>0? 'background-color: '+group_backgroud_color+';' : '' %> } <%- namespace %>.view-landing .view-landing-category { <%- category_backgroud_color.length>0? 'background-color: '+category_backgroud_color+';' : '' %> } <%- namespace %>.view-landing .single-category .view-landing-category .category-marquee { <%- category_backgroud_color.length>0? 'background-color: '+category_backgroud_color+';' : '' %> <% if(category_backgroud_color !== '' && category_backgroud_color_gradient !== '') { %> background: <%- category_backgroud_color %>; /* Old browsers */ background: -moz-linear-gradient(<%- category_backgroud_color %>, <%- category_backgroud_color_gradient %>); /* FF3.6+ */ background: -webkit-linear-gradient(<%- category_backgroud_color %>, <%- category_backgroud_color_gradient %>); /* Chrome10+,Safari5.1+ */ background: -o-linear-gradient(<%- category_backgroud_color %>, <%- category_backgroud_color_gradient %>); /* Opera 11.10+ */ background: -ms-linear-gradient(<%- category_backgroud_color %>, <%- category_backgroud_color_gradient %>); /* IE10+ */ background: linear-gradient(<%- category_backgroud_color %>, <%- category_backgroud_color_gradient %>); /* W3C */ filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='<%- category_backgroud_color %>', endColorstr='<%- category_backgroud_color_gradient %>',GradientType=0 ); /* IE6-9 */ <% } %> } "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append( namespace )
    ; __append(" .wrapper-text { ")
    ; __append( text_display==='false'? 'display: none;' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(" .category-group-name { ")
    ; __append( group_text_color.length>0? 'color: '+group_text_color+';' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(" .category-name { ")
    ; __append( category_text_color.length>0? 'color: '+category_text_color+';' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(".view-landing .view-landing-category-group .category-group-marquee { ")
    ; __append( group_backgroud_color.length>0? 'background-color: '+group_backgroud_color+';' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(".view-landing .view-landing-category { ")
    ; __append( category_backgroud_color.length>0? 'background-color: '+category_backgroud_color+';' : '' )
    ; __append(" } ")
    ; __append( namespace )
    ; __append(".view-landing .single-category .view-landing-category .category-marquee { ")
    ; __append( category_backgroud_color.length>0? 'background-color: '+category_backgroud_color+';' : '' )
    ; __append(" ")
    ;  if(category_backgroud_color !== '' && category_backgroud_color_gradient !== '') { 
    ; __append(" background: ")
    ; __append( category_backgroud_color )
    ; __append("; /* Old browsers */ background: -moz-linear-gradient(")
    ; __append( category_backgroud_color )
    ; __append(", ")
    ; __append( category_backgroud_color_gradient )
    ; __append("); /* FF3.6+ */ background: -webkit-linear-gradient(")
    ; __append( category_backgroud_color )
    ; __append(", ")
    ; __append( category_backgroud_color_gradient )
    ; __append("); /* Chrome10+,Safari5.1+ */ background: -o-linear-gradient(")
    ; __append( category_backgroud_color )
    ; __append(", ")
    ; __append( category_backgroud_color_gradient )
    ; __append("); /* Opera 11.10+ */ background: -ms-linear-gradient(")
    ; __append( category_backgroud_color )
    ; __append(", ")
    ; __append( category_backgroud_color_gradient )
    ; __append("); /* IE10+ */ background: linear-gradient(")
    ; __append( category_backgroud_color )
    ; __append(", ")
    ; __append( category_backgroud_color_gradient )
    ; __append("); /* W3C */ filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='")
    ; __append( category_backgroud_color )
    ; __append("', endColorstr='")
    ; __append( category_backgroud_color_gradient )
    ; __append("',GradientType=0 ); /* IE6-9 */ ")
    ;  } 
    ; __append(" } ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}