'use strict';
import _ from 'lodash';
import $ from 'jquery';

import template from './../templates/error.ejs';
import BaseView from './../views/base';

export default BaseView.extend({
    tagName: 'div',
    className: 'view-error',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .close': 'close'
        });
    },
    /**
     *
     */
    initialize: function(options) {
        _.bindAll(this, 'render');
        this.id = options.id;
    
        this.closeModal = options.closeModal;
        this.applyCustomStyles();
    },
    /**
     *
     */
    render: function() {
        console.log('error view', _.result(this, 'events'));
        this.$el.html(template(_.extend(this.model, {
            isClosedButtonEnabled: this.id !== 'error-window'
        })));
        return this;
    },
    applyCustomStyles: function(){
        $('.view-footer').addClass(this.id);
    },
    /**
     *
     * @param e
     */
    close: function(e) {
        e.preventDefault();
        this.closeModal();
    }
});
