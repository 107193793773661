'use strict';

import CmsModel from 'tscom-cms';
import _ from 'lodash';
import Backbone from 'backbone';
import $ from 'jquery';

export default Backbone.Collection.extend({
    model: CmsModel,
    /**
     * We can't request multiple wids at the same time, so we'll
     * just have to have each model make the call.
     */
    fetch: function() {
        const promises = _.invoke(this.models, 'fetch');

        return $.when.apply($, promises);
    }
});
