module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<nav class=\"cross-category\"></nav> <section class=\"user-controls\"></section> <div class=\"wrapper-text\"> <div class=\"heading-wrapper\"> <h3><%- name %></h3> <% if (sponsor_url && sponsor_logo) { %> <div class=\"sponsor\"> <span class=\"sponsor__cta\"><%- copy.sponsor_copy %></span> <a href=\"<%- sponsor_url %>\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"sponsor__linkout\"> <img src=\"<%- sponsor_logo %>\" alt=\"<%- sponsor_accessibility_copy %>\"> </a> </div> <% } %> </div> <p class=\"vote-cta\"> <span class=\"description\"><%- description %></span> <span class=\"count <%- isCountVisible? '': 'hidden'%>\"></span> </p> </div> <div class=\"clearfix voting-grid-options\"></div> <nav class=\"footer-control\"> <ul> <li class=\"swipe-to-prev\"> <a href=\"\" class=\"to-prev\"><i class=\"icon-arrow-left\"></i><%- nav.prevText %></a> </li> <li> <a href=\"\" class=\"to-all\"><%- nav.navSeeAllText %></a> </li> <li class=\"swipe-to-next\"> <a href=\"\" class=\"to-next\"><%- nav.nextText %><i class=\"icon-arrow-right\"></i></a> </li> </ul> </nav> "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<nav class=\"cross-category\"></nav> <section class=\"user-controls\"></section> <div class=\"wrapper-text\"> <div class=\"heading-wrapper\"> <h3>")
    ; __append( name )
    ; __append("</h3> ")
    ;  if (sponsor_url && sponsor_logo) { 
    ; __append(" <div class=\"sponsor\"> <span class=\"sponsor__cta\">")
    ; __append( copy.sponsor_copy )
    ; __append("</span> <a href=\"")
    ; __append( sponsor_url )
    ; __append("\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"sponsor__linkout\"> <img src=\"")
    ; __append( sponsor_logo )
    ; __append("\" alt=\"")
    ; __append( sponsor_accessibility_copy )
    ; __append("\"> </a> </div> ")
    ;  } 
    ; __append(" </div> <p class=\"vote-cta\"> <span class=\"description\">")
    ; __append( description )
    ; __append("</span> <span class=\"count ")
    ; __append( isCountVisible? '': 'hidden')
    ; __append("\"></span> </p> </div> <div class=\"clearfix voting-grid-options\"></div> <nav class=\"footer-control\"> <ul> <li class=\"swipe-to-prev\"> <a href=\"\" class=\"to-prev\"><i class=\"icon-arrow-left\"></i>")
    ; __append( nav.prevText )
    ; __append("</a> </li> <li> <a href=\"\" class=\"to-all\">")
    ; __append( nav.navSeeAllText )
    ; __append("</a> </li> <li class=\"swipe-to-next\"> <a href=\"\" class=\"to-next\">")
    ; __append( nav.nextText )
    ; __append("<i class=\"icon-arrow-right\"></i></a> </li> </ul> </nav> ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}