module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<div class=\"wrapper-logo\"> <%- logo.link.url.length>0? '<a href=\" '+logo.link.url+' \" target=\"'+logo.link.target+'\">' : '' %> <% if (logo.image.length>0) { %> <figure class=\"logo\"> <img src=\"<%- logo.image %>\"> </figure> <% } %> <%- logo.link.url.length>0? '</a>' : '' %> <% if(external_link.url.length>0 && external_link.display === \"true\") { %> <div class=\"external-link\"> <a href=\"<%-external_link.url%>\" target=\"<%- external_link.target %>\"><%- external_link.text %></a> </div> <% } %> </div> <div class=\"wrapper-text\"> <h1><%- headline %></h1> <p><%- paragraph %></p> </div> "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"wrapper-logo\"> ")
    ; __append( logo.link.url.length>0? '<a href=" '+logo.link.url+' " target="'+logo.link.target+'">' : '' )
    ; __append(" ")
    ;  if (logo.image.length>0) { 
    ; __append(" <figure class=\"logo\"> <img src=\"")
    ; __append( logo.image )
    ; __append("\"> </figure> ")
    ;  } 
    ; __append(" ")
    ; __append( logo.link.url.length>0? '</a>' : '' )
    ; __append(" ")
    ;  if(external_link.url.length>0 && external_link.display === "true") { 
    ; __append(" <div class=\"external-link\"> <a href=\"")
    ; __append(external_link.url)
    ; __append("\" target=\"")
    ; __append( external_link.target )
    ; __append("\">")
    ; __append( external_link.text )
    ; __append("</a> </div> ")
    ;  } 
    ; __append(" </div> <div class=\"wrapper-text\"> <h1>")
    ; __append( headline )
    ; __append("</h1> <p>")
    ; __append( paragraph )
    ; __append("</p> </div> ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}