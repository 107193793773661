export const VOTE_CONSTANTS = {
    MIN: 0,
    MAX: 50
}

export const VOTE_ACTION_TYPES = {
    GET: 'get',
    VOTE: 'vote'
}

export const MODEL_NAMES = {
    CONNECT_GET: 'ConnectGet',
    CONNECT_VOTE: 'ConnectVote',
    CONNECT_TURBO: 'ConnectTurbo'
}

export const VOTE_API_VERSION_CHECK = 'P7BSQtAlEKSmpAitLidd';
export const TURBO_VERSION_CHECK = 'wEYIe1GxcwH0NKuSO9ax';
export const CATEGORY_PREFIX = 'cat';
export const DEVICE_TYPES = {
    NATIVE: 'native',
    WEB: 'web',
    INSTAGRAM: 'instagram'
};
