'use strict';
import _ from 'lodash';
import Backbone from 'backbone';
import template from './../templates/css.ejs';

export default Backbone.View.extend({
    tagName: 'style',
    className: 'view-css',
    initialize: function(options) {
        this.template = options.template ? options.template : template;
        this.namespace = options.namespace || '';

        this.model = _.extend(_.clone(this.model, 'true'), {
            namespace: this.namespace
        });

        this.$el.prop('type', 'text/css');
    },
    /**
     *
     */
    render: function() {
        this.$el.append(this.template(this.model));
        return this;
    }
});
