'use strict';
import _ from 'lodash';
import template from './../templates/vote-item.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';

export default BaseView.extend({
    isOverlimit: false,
    className: 'view-vote-item',
    tagName: 'li',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .item-wrapper:not(.inactive) .btn-vote': 'vote'
        });
    },
    /**
     *
     */
    initialize: function() {
        _.bindAll(this, 'render');

        // prevent concurrent submissions
        this.listenTo(
            PubSub,
            'vote',
            function() {
                this.disableSelection = true;
                this.$('.item-wrapper').addClass('inactive');
            }.bind(this)
        );

        this.listenTo(
            PubSub,
            'userNameUpdated',
            function() {
                this.resetState();
                this.$('.item-wrapper').removeClass('inactive');
            }.bind(this)
        );

        this.listenTo(
            PubSub,
            'navigate',
            function(path) {
                if (path === 'error/overlimit') {
                    this.$('.item-wrapper').removeClass('inactive');
                    this.isOverlimit = true;
                }
            }.bind(this)
        );
        this.listenTo(PubSub, 'reset:vote-item', this.resetState.bind(this));
        this.listenTo(PubSub, 'auth:user-status', this.updateVoteItem.bind(this));
        this.listenTo(this.model.model, 'change', this.render);
        this.listenTo(PubSub, 'category-votes-updated', this.updateVoteButtonState.bind(this));
    },
    /**
     *
     */
    render: function() {
        this.$el.html(
            template({
                model: this.model.model.toJSON(),
                copy: this.model.copy,
                isCountVisible: this.model.isCountVisible,
                voteCountCopy: this.generateVoteCountCopy()
            })
        );
        return this;
    },
    /**
     * [vote description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    vote: function(e) {
        e.preventDefault();
        const isAuth = this.model.authModel.get('isAuthorized');
        if (!isAuth) {
            this.listenToOnce(this.model.authModel, 'change:isAuthorized', () => {
              if (isAuth) {
                this.vote(e);
              }
            });
            PubSub.trigger('user-login');
            return;
        }
        if (this.isOverlimit) {
            return this.triggerOverlimit();
        }

        if (this.disableSelection) {
            return;
        }
        if (this.model.copy.enable_one_click === 'false') {
            PubSub.trigger(
                'navigate',
                'vote-detail/' +
                    this.model.model.get('category_id') +
                    '-' +
                    this.model.model.id
            );
        } else {
            const voteModel = this.model.model.id;
            console.log('voteModel', voteModel, this.model.model);
            this.$('.btn-vote')
                .css({
                    'font-size': '80%',
                    padding: '10px 18px'
                })
                .button('loading');
            PubSub.trigger('vote', this.model.model);
        }
    },
    /**
     * triggerOverlimit
     */
    triggerOverlimit: function() {
        console.warn('triggerOverlimit', this.model.model.get('id'));
        PubSub.trigger('navigate', 'error/overlimit');
    },

    updateVoteItem: function(isAuth){
        const el = this.$el.find('.vote-count');
        if(!el) return;

        if(isAuth){
            this.model.isCountVisible = true;
        }else{
            this.model.isCountVisible = false;
        }

        this.render();
    },
    generateVoteCountCopy: function(){
        const count = parseInt(this.model.model.get('voteCount'), 10);
        let result = '';

        if(count === 0 || count > 1){
            result = `${count} ${this.model.copy.vote_count_copy_plural}`;
        }else{
            result = `${count} ${this.model.copy.vote_count_copy_singular}`;
        }
        return result;

    },
    resetState: function(){
        this.disableSelection = false;
        this.isOverlimit = false;
        this.updateVoteButtonState();
    },
    updateVoteButtonState: function(){
        const remainingVotes = this.model.categoryCollection.get('remainingVotes');
        if(remainingVotes === 0){
            this.$('.item-wrapper').addClass('inactive');
            this.$('.btn-vote').prop('disabled', true);
        }else{
            this.$('.item-wrapper').removeClass('inactive');
            this.$('.btn-vote').prop('disabled', false);

        }
    }
});
