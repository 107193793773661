'use strict';
import _ from 'lodash';
import template from './../templates/turbo-banner.ejs';
import BaseView from './base';

export default BaseView.extend({
    template: template,
    className: 'view-turbo-banner',
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
        });
    },
    /**
     *
     */
    initialize: function( options ) {
        _.bindAll(this, 'render');
        this.copy = options.copy;
    },
    /**
     *
     */
    render: function() {
        this.$el.html(this.template(this.copy));
        return this;
    }
});
