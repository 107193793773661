'use strict';
import _ from 'lodash';
import Backbone from 'backbone';
import Model from './../models/vote';

export default Backbone.Collection.extend({
    model: Model,
    /**
     *
     */
    initialize: function() {},
    comparator: function(a, b) {
        const direction = 1; // Asc
        //const direction = -1; // Desc

        a = a.get('firstname').replace('"', '');
        b = b.get('firstname').replace('"', '');
        return direction * a.localeCompare(b);
    },
    /**
     * [next description]
     * @param  {[type]}   option [description]
     * @return {Function}        [description]
     */
    next: function(option) {
        const model = this.at(this.indexOf(option) + 1);

        if (_.isUndefined(model)) {
            model = this.last();
        }

        return model;
    },
    /**
     * [prev description]
     * @param  {[type]} option [description]
     * @return {[type]}        [description]
     */
    prev: function(option) {
        const model = this.at(this.indexOf(option) - 1);

        if (_.isUndefined(model)) {
            model = this.first();
        }

        return model;
    }
});
