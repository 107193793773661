'use strict';
import _ from 'lodash';
import Backbone from 'backbone';

export default Backbone.Model.extend({
    url: '',
    defaults: function() {
        return {
            name: '',
            id: '',
            group_id: '',
            active: '0',
            image: '',
            voteData: [],
            voteCollection: undefined,
            remainingVotes: 0
        };
    },
    /**
     *
     */
    initialize: function() {},
    /**
     *
     */
    validate: function() {},
    /**
     * Override sync method to make requests to the Vote API.
     * @param {string} method - Will be one of: 'create', 'read', 'update', 'destroy'.  We use the value to determine action type.
     * @param {Backbone.Model} model - A reference to this model.
     * @param {object} options - A JS object with options for $.ajax.
     */
    sync: function(method, model, options) {
        const params = {
            url: this.url,
            dataType: 'json',
            crossDomain: true
        };

        _.extend(params, options);

        this.xhr = Backbone.sync(method, model, params)
            .success(_.bind(this.syncSuccess, this))
            .fail(_.bind(this.syncError, this));

        return this.xhr;
    },
    /**
     *
     */
    fetch: function() {
        // this.sync('read', this, options);
        console.warn('The category model does not support "fetch"');
    },
    /**
     *
     */
    save: function() {
        // this.sync('create', this, options);
        console.warn('The category model does not support "save"');
    },
    /**
     *
     */
    destroy: function() {
        console.warn('The category model does not support "destroy"');
    },
    /**
     * Callback for If AJAX send vote success
     */
    syncSuccess: function(response) {
        console.log('syncSuccess', response);
    },
    /**
     * Callback for AJAX send vote fail
     */
    syncError: function(response) {
        console.log('syncError', response);
    },
    /**
     *
     */
    parse: function(response) {
        return response;
    }
});
